import React from "react";
import "../../auth.css";
import logo from "../../../../assets/login/login_logo.png";
import { Link } from "react-router-dom";
import ResetContentForm from "./ResetContentForm";
import LanguageContentSection from "../../common/LanguageContentSection";
import { useTranslation } from "react-i18next";

const ResetContentSection = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* Reset content section start */}
      <div className="login-content-section">
        {/* language selection start */}
        <div className="login-language-section">
          <LanguageContentSection />
        </div>
        {/* language selection end */}

        {/* Reset content form start */}
        <div className="login-content-form reset-form">
          <div className="login-catapult-logo">
            <img crossOrigin="anonymous" src={logo} alt="Website Logo" className="logo-image" />
          </div>
          <div className="login-form">
            <ResetContentForm />
          </div>
        </div>
        {/* Reset content form end */}

        {/* Reset content footer start */}
        <div className="login-footer">
          <Link to="/">{t("COMMON.LOGIN.NOW")}</Link>
        </div>
        {/* Reset content footer end */}
      </div>
      {/* Reset content section end */}
    </div>
  );
};

export default ResetContentSection;
