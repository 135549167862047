/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import { verifyOtpService } from "../../../../services/authService/verifyOTPService";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { resendOtpService } from "../../../../services/authService/resendOtpService";

const OtpVerifyContentForm = () => {
    // states start
    const [resendTimer, setResendTimer] = useState(0);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const signupEmailValue = useSelector((state) => state?.auth?.email);
    const [code, setCode] = useState(null);
    // states end

    // initial values of all fields
    const initialValues = {};

    const validationSchema = Yup.object({});

    // function for submit all values
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: () => {
            verifyOtp();
        },
    });
    const verifyOtp = () => {
        setIsSubmitting(true);
        setCode(null);
        verifyOtpService
            .addVerifyOtpData({ email: signupEmailValue, otp: code })
            .then((res) => {
                toast.success(res?.data?.message);
                if (res?.data?.data?.token) {
                    localStorage.setItem("verify_token", res?.data?.data?.token);
                    navigate("/reset-password");
                } else {
                    toast.error(res?.data?.message);
                }
            })
            .catch((ex) => {
                toast.error(ex?.response?.data?.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleChange = (code) => {
        setCode(code);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const handleResendOtp = async (val) => {
        if (val === true) {
            setResendTimer(120);
            resendOtpService
                .verifyResendOtp({
                    email: signupEmailValue,
                })
                .then((response) => {
                    setCode(null);
                    toast.success(response?.data?.message);
                });
        } else {
            setResendTimer(120);
        }
    };

    useEffect(() => {
        if (resendTimer > 0) {
            const intervalId = setInterval(() => {
                setResendTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [resendTimer]);

    useEffect(() => {
        if (signupEmailValue === null) {
            navigate("/");
        }
        handleResendOtp();
    }, []);

    return (
        <>
            {/*  JSX code for login form start */}
            <div className="login-form-content">
                <div className="login-form-title mb-4 mb-sm-5 ">
                    <h2>Enter the OTP</h2>
                    <p>Enter the OTP that we sent to your email {signupEmailValue}.</p>
                    <p>Be careful not to share the code with anyone.</p>
                </div>
                <form className="login-form-field" onSubmit={formik.handleSubmit}>
                    <fieldset>
                        {/* otp input field start */}
                        <div className="form-group mb-5 d-flex justify-content-center">
                            <OTPInput
                                value={code}
                                onChange={handleChange}
                                numInputs={5}
                                renderInput={(props) => <input {...props} />}
                                isInputNum={true}
                                renderSeparator={<span className="mx-0 mx-lg-2 mx-md-2 mx-sm-2">-</span>}
                                shouldAutoFocus={true}
                                inputType="number"
                                inputStyle={{
                                    border: "1px solid",
                                    borderRadius: "8px",
                                    height: "45px",
                                    width: "45px",
                                    fontSize: "12px",
                                    color: "#000",
                                    fontWeight: "400",
                                    caretColor: "blue",
                                }}
                                focusStyle={{
                                    border: "1px solid #CFD3DB",
                                    outline: "none",
                                }}
                            />
                        </div>
                        {/* otp input field end  */}

                        {/* login button start  */}
                        <button type="submit" className="btn login-button" disabled={isSubmitting || code === null || code.length !== 5}>
                            {t("COMMON.VERIFY.OTP")}
                        </button>
                        {/* login button end  */}

                        <div className="d-flex justify-content-center mt-3">
                            <span className="me-2">Didn't get the code ? </span>
                            <button className="resend_btn" type="button" onClick={() => handleResendOtp(true)} disabled={resendTimer > 0}>
                                {resendTimer > 0 ? `(${formatTime(resendTimer)})` : " Resend It"}
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
            {/*  JSX code for login form end */}
        </>
    );
};

export default OtpVerifyContentForm;
