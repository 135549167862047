function convertUtcToPst8pdt(utcTimeString) {
    const utcDate = new Date(utcTimeString);

    // Calculate PST/PDT offset based on current month
    const offset = new Date().getHours() >= 7 ? -7 : -8; // -7 for PDT, -8 for PST
    const pst8pdtDate = new Date(utcDate.getTime() + offset * 60 * 60 * 1000);

    return pst8pdtDate;
}

export default convertUtcToPst8pdt;
