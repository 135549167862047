import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Zendesk from "react-zendesk";
import AppRoutes from "./routes";
import "./App.css";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        // window.zESettings = {
        //   webWidget: {
        //     chat: {
        //       suppress: false,
        //     },
        //   },
        // };
    }, []);

    return (
        <div className="App">
            <AppRoutes />
            {process.env?.REACT_APP_ZENDESK_KEY && <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} />}
        </div>
    );
}

export default App;
