import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetService } from "../../../../services/authService/resetService";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ResetContentForm = () => {
  // states start
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const signupEmailValue = useSelector((state) => state?.auth?.email);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // states end

  // initial values of all fields
  const initialValues = {
    email: signupEmailValue,
    password: "",
  };

  // validation of all fields
  const validationSchema = Yup.object({
    email: Yup.string()
      .test(
        "noLeadingSpaces",
        "Invalid Input, Please avoid leading spaces at the beginning of the field",
        (value) => {
          return !value || value.trimStart() === value;
        }
      )
      .email(t("COMMON.EMAIL.INVALID_FORMAT"))
      .required(t("COMMON.EMAIL.REQUIRED")),
    password: Yup.string()
      .test(
        "noLeadingSpaces",
        "Invalid Input, Please avoid leading spaces at the beginning of the field",
        (value) => {
          return !value || value.trimStart() === value;
        }
      )
      .required(t("COMMON.NEW.PASSWORD.REQUIRED"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        t("COMMON.PASSWORD.INVALID_FORMAT")
      ),
    confirmPassword: Yup.string()
      .test(
        "noLeadingSpaces",
        "Invalid Input, Please avoid leading spaces at the beginning of the field",
        (value) => {
          return !value || value.trimStart() === value;
        }
      )
      .oneOf([Yup.ref("password"), null], t("COMMON.PASSWORD.CONFIRM_MISMATCH"))
      .required(t("COMMON.PASSWORD.CONFIRM_REQUIRED")),
  });

  // function for submit all values
  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (localStorage.getItem("verify_token")) {
      values["token"] = localStorage.getItem("verify_token");
      resetService
        .addResetData(values)
        .then((response) => {
          toast.success(response?.data?.message);
          navigate("/");
        })
        .catch((ex) => {
          toast.error(ex?.response?.data?.message);
        });
    }
  };

  // function for hide password field
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <>
      {/*  JSX code for reset form start */}
      <div className="login-form-content">
        <div className="login-form-title mb-4 mb-sm-5 ">
          <h2>{t("COMMON.RESET.PASSWORD")}</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="login-form-field">
          {/* password input field start  */}
          <div className="form-group mb-4 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder={t("COMMON.NEW.PASSWORD")}
              className="form-control login-form-group"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                setIsSubmitting(false);
              }}
            />
            <button
              className="btn position-absolute top-0 end-0 eye-btn"
              type="button"
              id="show-password"
              onClick={handlePassword}
              style={{ height: "48px" }}
            >
              {showPassword ? (
                <AiFillEye className="header-icon" />
              ) : (
                <AiFillEyeInvisible className="header-icon" />
              )}
            </button>
            {formik.touched.password && formik.errors.password ? (
              <div className="text-danger" style={{ fontSize: "15px" }}>
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          {/* password input field end  */}
          {/* confirm password input field start */}
          <div className="form-group mb-4 position-relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              placeholder={t("COMMON.PASSWORD.CONFIRM")}
              className="form-control login-form-group"
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                setIsSubmitting(false);
              }}
            />
            <button
              className="btn position-absolute top-0 end-0 eye-btn"
              type="button"
              id="show-confirm-password"
              onClick={handleConfirmPassword}
              style={{ height: "48px" }}
            >
              {showConfirmPassword ? (
                <AiFillEye className="header-icon" />
              ) : (
                <AiFillEyeInvisible className="header-icon" />
              )}
            </button>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="text-danger" style={{ fontSize: "15px" }}>
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
          {/* confirm password input field end */}

          {/* reset button start  */}
          <button
            type="submit"
            className="btn login-button"
            disabled={!formik.dirty || isSubmitting}
          >
            {t("COMMON.RESET.NOW")}
          </button>
          {/* reset button end  */}
        </form>
      </div>
      {/*  JSX code for reset form end */}
    </>
  );
};

export default ResetContentForm;
