import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CongratulationBadgeImg from "../../../assets/congrulation-badge.png";
import "./congratulations-modal.css";
const CongratulationsModal = ({ onClose, setShowCongratulationsModal }) => {
    const masterClass = localStorage.getItem("masterClass");

    const handleClose = () => {
        onClose();
        setShowCongratulationsModal(false);
    };

    const surveyUrl = masterClass === "Career Readiness" ? " https://survey.alchemer.com/s3/7708797/Career-Readiness-Masterclass-Series-Post-Survey" : "https://catapult.fillout.com/pre-post-survey";

    return (
        <>
            <Modal show="true" size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="congratulations-modal">
                <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                    <div className="row w-ful justify-content-center align-center">
                        <img src={CongratulationBadgeImg} style={{ width: "100px" }} alt="" />
                        <p className="congratulations-text">Congratulations</p>
                        <p className="congratulations-message-text">Be a part of improving professional development for educators nationally. Complete the eternal evaluation post-survey today.</p>
                        <Button variant="primary" className="keep-learning-button" onClick={() => handleClose()}>
                            <a href={surveyUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                                Keep Learning
                            </a>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CongratulationsModal;
