import React from "react";
import LockedSessionCard from "../SessionCards/LockedSessionCard";

const LockedSessions = ({ institutionKeyNotes, flag }) => {
    return (
        <div className="pb-3 pe-3">
            {institutionKeyNotes
                .filter((session) => session.start_date > new Date().toISOString().substring(0, 10))
                .map((session) => (
                    <LockedSessionCard key={session.id} session={session} flag={flag} />
                ))}
        </div>
    );
};

export default LockedSessions;
