import apiClient from "../../utils/apiClient";

const GET_MASTERCLASS = "/get-master-class";
const ADD_RESOURCE = "/resources";
const GET_ALL_RESOURCES = "/resources";
const GET_RESORCE_URL = "/get-resource";

const getAllMasterClassData = () => {
    const url = `${GET_MASTERCLASS}`;
    return apiClient.get(url);
};

const addResource = (data) => {
    const url = `${ADD_RESOURCE}`;
    return apiClient.post(url, data);
};

const getAllResources = () => {
    const url = `${GET_ALL_RESOURCES}`;
    return apiClient.get(url);
};

const getResorceUrl = (id) => {
    const url = `${GET_RESORCE_URL}?resource_id=${id}`;
    return apiClient.get(url);
};

export const ResourcesService = {
    getAllMasterClassData,
    addResource,
    getAllResources,
    getResorceUrl,
};
