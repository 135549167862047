import apiClient from "../../utils/apiClient";

const ALL_PROJECTS_DATA = "/project";
const MY_PROJECTS_DATA = "/get-my-project";
const ADD_PROJECTS_DATA = "/project";
const PRESIGN_URL = "/get-presign-url";
const GET_PROJECT_ID_DATA = "project";
const UPDATED_PROJECT_ID_DATA = "project";
const ADD_LIKE = "project-like";
const ADD_COMMENT_LIKE = "comment-like";
const ADD_VIEW = "project-view";
const DELETE_MEDIA_FILE = "delete-media-file";
const GET_ALL_COMMENTS = "get-comments";
const ADD_COMMENTS_DATA = "project-comment";

const getAllProjectsData = (limit, page) => {
    const url = `${ALL_PROJECTS_DATA}?limit=${limit}&page=${page}`;
    return apiClient.get(url);
};

const addMyProjectsData = (data) => {
    const url = `${ADD_PROJECTS_DATA}`;
    return apiClient.post(url, data);
};

const getPresignUrl = (file_name) => {
    const url = `${PRESIGN_URL}?file_name=${file_name}`;
    return apiClient.get(url);
};

const getMyProjectsData = (limit, page) => {
    const url = `${MY_PROJECTS_DATA}?limit=${limit}&page=${page}`;
    return apiClient.get(url);
};

const getAllProjectsIdData = (id) => {
    const url = `${GET_PROJECT_ID_DATA}/${id}`;
    return apiClient.get(url);
};
const getEditProjectsIdData = (id) => {
    const url = `${GET_PROJECT_ID_DATA}/${id}/edit`;
    return apiClient.get(url);
};

const updatedProjectData = (id, data) => {
    const url = `${UPDATED_PROJECT_ID_DATA}/${id}`;
    return apiClient.put(url, data);
};

const deleteMediaFile = (path) => {
    const url = `${DELETE_MEDIA_FILE}`;
    return apiClient.post(url, path);
};

const addLike = (id) => {
    const url = `${ADD_LIKE}`;
    return apiClient.post(url, id);
};

const addCommentLike = (id) => {
    const url = `${ADD_COMMENT_LIKE}`;
    return apiClient.post(url, id);
};
const addView = (id) => {
    const url = `${ADD_VIEW}`;
    return apiClient.post(url, id);
};

const getAllComments = (id) => {
    const url = `${GET_ALL_COMMENTS}?project_id=${id}`;
    return apiClient.get(url);
};

const addComments = (data) => {
    const url = `${ADD_COMMENTS_DATA}`;
    return apiClient.post(url, data);
};
export const ProjectsService = {
    getAllProjectsData,
    addMyProjectsData,
    getPresignUrl,
    getMyProjectsData,
    getAllProjectsIdData,
    getEditProjectsIdData,
    updatedProjectData,
    deleteMediaFile,
    addLike,
    addView,
    addComments,
    getAllComments,
    addCommentLike,
};
