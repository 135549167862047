import React, { useEffect, useState } from "react";
import ReplyIcon from "../../../assets/reply2.svg";
import AddPost from "../modal/add-post";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { miniBookService } from "../../../services/miniBookService/miniBookService";
import "../miniCourses.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const Replies = ({ discussion, handleAddPostData, isChild = false, getCommunity }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [tempDiscussion, setTempDiscussion] = useState();
    const userData = useSelector((state) => state?.auth?.userData);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        setTempDiscussion(discussion);
    }, [discussion]);

    const handleAddPost = () => {
        setShowAddModal(true);
    };

    const handleEditPost = () => {
        setShowEditModal(true);
    };

    const handleCloseEditPost = (data) => {
        setShowEditModal(false);
        handleReload(discussion.discussion.general_community_question_id, data);
    };

    const closeAddPost = (data) => {
        setShowAddModal(false);
        handleReload(data);
    };

    const handleReload = (data) => {
        handleAddPostData(tempDiscussion?.discussion?.play_book_course_question_id, data);
    };

    const handleLike = () => {
        miniBookService.addLike(tempDiscussion?.discussion.id).then((res) => {
            handleAddPostData(tempDiscussion?.discussion.play_book_course_question_id, res);
        });
    };

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer?.split(linkRegex);
        return parts?.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    const handleDeleteConfirmation = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete();
            }
        });
    };

    const handleDelete = () => {
        miniBookService
            .deletePlaybookDiscussion(tempDiscussion?.discussion.id)
            .then((res) => {
                if (res) {
                    Swal.fire("Deleted!", "Your post has been deleted.", "success");
                    getCommunity();
                }
            })
            .catch((error) => {
                Swal.fire("Error!", "Failed to delete the post.", "error");
            });
    };

    return (
        <>
            <div className={isChild ? "mt-2 mb-2 child-container" : "reply-container mt-2 mb-2"}>
                <div className="comments-display">
                    <div className="d-flex align-center justify-space-between">
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-lg-row w-100 justify-space-between">
                                <div className="reply-text" style={{ wordBreak: "break-all" }}>
                                    {/* <div>{discussion.discussion.title}</div> */}
                                    <div>{renderAnswer(tempDiscussion?.discussion.answer)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-end align-items-end gap-1">
                            <div className="cursor-pointer align-center d-flex" onClick={() => handleLike()}>
                                {/* <img src={ThumpsUp}></img> */}
                                {tempDiscussion?.discussion?.likes > 0 ? (
                                    <div style={{ fontSize: "12px", marginRight: "5px" }} className="wd-35">
                                        {tempDiscussion?.discussion?.likes + " " + (tempDiscussion?.discussion?.likes > 1 ? "Likes" : "Like")}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {tempDiscussion?.discussion?.hasUserLiked ? (
                                    <>
                                        <AiFillLike />
                                    </>
                                ) : (
                                    <>
                                        <AiOutlineLike />
                                    </>
                                )}
                            </div>
                            {discussion?.discussion?.user_id == userData?.id && (
                                <div className="d-flex gap-1">
                                    <button className="border-0 p-0" style={{ background: "unset", color: "#bc9c6c" }} onClick={() => handleEditPost()}>
                                        <FaRegEdit fontSize={20} />
                                    </button>
                                    <button className="border-0 p-0" style={{ background: "unset", color: "rgba(139, 0, 50, 1)" }} onClick={handleDeleteConfirmation}>
                                        <MdDelete fontSize={24} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex flex-row mt-2 mb-2 cursor-pointer" onClick={() => handleAddPost()}>
                        <img src={ReplyIcon}></img>

                        <span className="reply-t">Reply</span>
                    </div>
                    {tempDiscussion?.replies.length > 0 && (
                        <>
                            {tempDiscussion?.replies.map((reply, key) => {
                                return <Replies discussion={reply} key={key} isChild={true} handleAddPostData={handleAddPostData} getCommunity={getCommunity} />;
                            })}
                        </>
                    )}
                </div>
            </div>
            {showAddModal && <AddPost onClose={closeAddPost} questionId={tempDiscussion?.discussion.play_book_course_question_id} parentId={tempDiscussion?.discussion.id} isReply={true} />}
            {showEditModal && (
                <AddPost
                    onClose={handleCloseEditPost}
                    questionId={tempDiscussion?.discussion.general_community_question_id}
                    parentId={tempDiscussion?.discussion.id}
                    isReply={true}
                    getCommunity={getCommunity}
                    isEdit={true}
                />
            )}
        </>
    );
};

export default Replies;
