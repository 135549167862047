import apiClient from "../../utils/apiClient";

const GET_CERTIFICATE = "/get-certificate";

const getCertificate = () => {
    const url = `${GET_CERTIFICATE}`;

    return apiClient.get(url);
};

export const certificateServices = {
    getCertificate,
};
