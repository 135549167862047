import apiClient from "../../utils/apiClient";

const GET_FAQ = "/faq";

const getFaqs = (page, limit) => {
    const url = `${GET_FAQ}?page=${page}&limit=${limit}`;

    return apiClient.get(url);
};

export const faqServices = {
    getFaqs,
};
