import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import notFound from "../../assets/not-found/not_found.png";
import PreviousSessionCard from "./PreviousSessionCard";
import "./PreviousSessions.css";
import { useTranslation } from "react-i18next";

const PreviousSessions = ({ sessions, showTitle = true, setIsPreviousSession, setIsPreRecordedSession, sessionFlag }) => {
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [firstPage, setFirstPage] = useState(1);
    const { t } = useTranslation();
    const itemsPerPage = 6;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedSessions = sessions?.slice(startIndex, endIndex);

    const handlePrevious = () => {
        if (page > firstPage) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        if (page < lastPage) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        setFirstPage(1);
        setLastPage(Math.ceil(sessions?.length / itemsPerPage));
        setPage(1);
    }, [sessions]);

    return (
        <div className="previous-sessions">
            <div className="card-layout-header d-flex justify-content-between">
                {showTitle && <h5>{t("DASHBOARD.TITLE.PREVIOUS.SESSIONS")}</h5>}

                {sessions?.length > 0
                    ? sessions.length > 6 &&
                      sessionFlag && (
                          <div>
                              <div className="gap-2  d-lg-flex d-none">
                                  <button className={page > firstPage ? "chevron" : "chevron disabled"} onClick={handlePrevious}>
                                      <BsChevronLeft />
                                  </button>
                                  <button className={page < lastPage ? "chevron" : "chevron disabled"} onClick={handleNext}>
                                      <BsChevronRight />
                                  </button>
                              </div>
                          </div>
                      )
                    : ""}
            </div>

            {sessions?.length > 0 && sessionFlag ? (
                <>
                    <div className="previous-sessions-body mt-5">
                        <div className="row">
                            {displayedSessions.map((data) => (
                                <PreviousSessionCard key={data.id} data={data} setIsPreRecordedSession={setIsPreRecordedSession} setIsPreviousSession={setIsPreviousSession} />
                            ))}
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center d-lg-none">
                        <button className={page > firstPage ? "chevron" : "chevron disabled"} onClick={handlePrevious}>
                            <BsChevronLeft />
                        </button>
                        <button className={page < lastPage ? "chevron" : "chevron disabled"} onClick={handleNext}>
                            <BsChevronRight />
                        </button>
                    </div>
                </>
            ) : (
                <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                    <img crossOrigin="anonymous" src={notFound} alt="data not found" width={400} />
                    <h4>No keynotes Added</h4>
                    <p className="fs-6">No keynotes available at the moment.</p>
                </div>
            )}
        </div>
    );
};

export default PreviousSessions;
