/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import ArrowBack from "../../../assets/arrow-back.png";
import X from "../../../assets/x.png";
import LinkedIn from "../../../assets/linkedIn.png";
import Facebook from "../../../assets/facebook.png";
import Chat2Icon from "../../../assets/chat-icon.svg";
import Enevlope from "../../../assets/enevlope.png";
import { FiEdit, FiEye } from "react-icons/fi";
import { ProjectsService } from "../../../services/projectsService/ProjectsService";
import EditMoreProjects from "../modal/edit-project";
import Loader from "../../../components/common/Loader";
import defaultImage from "../../../assets/avatarUser.png";
import avatarImage from "../../../assets/profile-settings/avatarImage.jpg";
import ShowMoreProjects from "./ShowMoreProjects";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import ShowAllComments from "./ShowAllComments";
import AddComments from "../modal/add-comments";
import calculateTimeDifference from "../../../utils/common-functions/DisplayTimeFormat";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ShowProjectsDetails = ({ setKey, myProjectsData, selectedProjectId, setMyKey, title, setSelectedProjectId, allProjectsData }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [projectIdData, setProjectIdData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [otherProjects, setOtherProjects] = useState([]);
    const [likeStatus, setLikeStatus] = useState("");
    const [allComments, setAllComments] = useState([]);
    const { impactId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const getAllCommetsIdData = () => {
        ProjectsService.getAllComments(impactId).then((response) => {
            setAllComments(response?.data?.data);
        });
    };

    const allProjectsIdData = () => {
        ProjectsService.getAllProjectsIdData(impactId).then((response) => {
            setSelectedProject(response?.data?.data?.main_project);
            setOtherProjects(response?.data?.data?.other_projects);
        });
    };

    const handleProjectCardClick = (projectId) => {
        setIsLoading(true);
        ProjectsService.getAllProjectsIdData(projectId).then((response) => {
            setSelectedProject(response?.data?.data?.main_project);
            setOtherProjects(response?.data?.data?.other_projects);
            setIsLoading(false);
        });
    };

    const allEditProjectsIdData = () => {
        setIsLoading(true);
        ProjectsService.getEditProjectsIdData(impactId).then((response) => {
            setProjectIdData(response?.data?.data);
            setIsLoading(false);
        });
    };

    // useEffect of call all api function
    useEffect(() => {
        allEditProjectsIdData();
        allProjectsIdData();
        getAllCommetsIdData();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handleBack = () => {
        navigate(-1);
        scrollToTop();
    };

    const handleAddProjects = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    };

    if (!selectedProject) {
        return null;
    }

    const handleLikeClick = (projectId) => {
        ProjectsService.addLike({ project_id: projectId })
            .then((response) => {
                setLikeStatus(response?.data?.status);
                allProjectsIdData();
            })
            .catch((error) => {});
    };

    const timeAgo = calculateTimeDifference(selectedProject?.created_at);

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                // Remove HTML tags from the text
                const textWithoutTags = part.replace(/(<([^>]+)>)/gi, "");
                return <span key={index}>{textWithoutTags}</span>;
            }
        });
    };

    return (
        <>
            {isLoading ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <div className="outer-card-layout show-all-projects-details">
                        <div className="d-flex justify-space-between mt-0 mt-lg-3 mt-md-3 my-project-btn align-items-center show-projects">
                            <div
                                onClick={() => {
                                    handleBack();
                                }}
                            >
                                <div className="back-button" style={{ width: "max-content" }}>
                                    <img crossOrigin="anonymous" src={ArrowBack} style={{ marginRight: "10px" }}></img>
                                    Back
                                </div>
                            </div>
                            {state == "allProject" ? (
                                ""
                            ) : (
                                <div
                                    onClick={() => {
                                        handleAddProjects();
                                    }}
                                >
                                    <div className="edit-button" style={{ width: "max-content" }}>
                                        <FiEdit />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="row mt-4 mb-3 project-container">
                            {state == "allProject" ? <div className="project-title">{selectedProject?.title}</div> : <div className="project-title">{projectIdData?.title}</div>}
                            <div className="project-detail mt-4">
                                <div className="row p-0 gap-2 px-lg-3 px-md-3 w-50 align-items-center">
                                    <div className="col-1 ">
                                        {selectedProject?.user?.full_profile_image !== null ? (
                                            <img crossOrigin="anonymous" className="user-profile-image" src={selectedProject?.user?.full_profile_image}></img>
                                        ) : (
                                            <img crossOrigin="anonymous" className="user-profile-image" src={defaultImage}></img>
                                        )}
                                    </div>
                                    <div className="col-7 mt-1 col-lg-4 col-md-4">
                                        <div className="project-author-name">
                                            {selectedProject?.user?.first_name}&nbsp;{selectedProject?.user?.last_name}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-md-6 mt-2 mt-lg-1 mt-md-1">
                                        <div className="all-project-time">Posted - {timeAgo}.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex gap-20 mt-3" style={{ gap: "20px" }}>
                                <div>
                                    {likeStatus === true || selectedProject?.hasUserLiked === 1 ? (
                                        <AiFillHeart
                                            fill="red"
                                            onClick={() => {
                                                handleLikeClick(selectedProject?.id);
                                            }}
                                            fontSize={22}
                                            style={{ cursor: "pointer" }}
                                        />
                                    ) : (
                                        <AiOutlineHeart
                                            onClick={() => {
                                                handleLikeClick(selectedProject?.id);
                                            }}
                                            fontSize={22}
                                            style={{ cursor: "pointer" }}
                                        />
                                    )}

                                    <span className="image-text-span fs-6">{selectedProject?.project_likes}</span>
                                </div>

                                <div>
                                    <FaRegCommentDots fontSize={22} />
                                    <span className="image-text-span fs-6">{selectedProject?.project_comments}</span>
                                </div>
                                <div>
                                    <FiEye fontSize={22} />
                                    <span className="image-text-span fs-6">{selectedProject?.project_views}</span>
                                </div>
                            </div>
                            <hr className="mt-4" style={{ borderColor: "#B7B7FE" }}></hr>
                            <div>
                                <div className="project-heading">Impact Description</div>
                                {
                                    state == "allProject"
                                        ? renderAnswer(selectedProject?.description)?.map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>)
                                        : // <div className="project-description  mt-4" dangerouslySetInnerHTML={{ __html: selectedProject?.description }}></div>
                                          renderAnswer(projectIdData?.description)?.map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>)
                                    // <div className="project-description mt-4" dangerouslySetInnerHTML={{ __html: projectIdData?.description }}></div>
                                }

                                {state == "allProject" && (
                                    <div className="mt-4">
                                        {selectedProject?.project_medias?.map((media) => (
                                            <div key={media.id} className="d-flex justify-content-center">
                                                {media.media_type === "image" ? (
                                                    <img crossOrigin="anonymous" src={media.full_file_path} alt={`Image ${media.id}`} className="show-projects-img-files" />
                                                ) : media.media_type === "video" ? (
                                                    <video controls crossOrigin="anonymous" src={media.full_file_path} className="show-projects-video-files"></video>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {state == "myProject" && (
                                    <div className="mt-4">
                                        {projectIdData?.project_medias?.map((media) => (
                                            <div key={media?.id} className="d-flex justify-content-center">
                                                {media?.media_type === "image" ? (
                                                    <img crossOrigin="anonymous" src={media?.full_file_path} alt={`Image ${media?.id}`} className="show-projects-img-files" />
                                                ) : media?.media_type === "video" ? (
                                                    <video controls crossOrigin="anonymous" src={media?.full_file_path} className="show-projects-video-files"></video>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {state == "allProject" && (
                                    <div className="d-flex mt-2 gap-20 align-center like" style={{ justifyContent: "space-between" }}>
                                        <div className="d-flex mt-4 gap-20 align-center">
                                            <div
                                                className="back-button"
                                                onClick={() => {
                                                    handleLikeClick(selectedProject?.id);
                                                }}
                                            >
                                                {likeStatus === true || selectedProject?.hasUserLiked === 1 ? (
                                                    <AiFillHeart fill="red" fontSize={22} style={{ cursor: "pointer" }} />
                                                ) : (
                                                    <AiOutlineHeart fontSize={22} style={{ cursor: "pointer" }} />
                                                )}
                                                Like
                                            </div>
                                        </div>
                                        {/* <div className="d-flex mt-4 gap-20 align-center">
                                            <div className="share">Share</div>
                                            <img crossOrigin="anonymous" src={Facebook}></img>
                                            <img crossOrigin="anonymous" src={X}></img>
                                            <img crossOrigin="anonymous" src={LinkedIn}></img>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                        </div>

                        {state == "allProject" && (
                            <>
                                <div className="mt-4 project-by">
                                    <div className="mt-4 author">A Impact By</div>
                                    <hr className="mt-4" style={{ borderColor: "#B7B7FE" }} />
                                    <div className="d-flex mt-4 gap-20 align-items-center">
                                        <div>
                                            {selectedProject?.user?.full_profile_image !== null ? (
                                                <img crossOrigin="anonymous" className="author-image" src={selectedProject?.user?.full_profile_image}></img>
                                            ) : (
                                                <img crossOrigin="anonymous" className="author-image" src={avatarImage}></img>
                                            )}
                                        </div>

                                        <div>
                                            <div className="author-name">
                                                {selectedProject?.user?.first_name}&nbsp;{selectedProject?.user?.last_name}
                                            </div>
                                            <div className="author-school mt-2">{selectedProject?.user?.institution_poc?.name}</div>
                                            <div className="mt-2 author-description">{selectedProject?.user?.bio}</div>
                                        </div>
                                    </div>
                                    <hr className="mt-4" style={{ borderColor: "#B7B7FE" }} />
                                </div>

                                {otherProjects.length > 0 && <ShowMoreProjects otherProjects={otherProjects} selectedProject={selectedProject} onProjectClick={handleProjectCardClick} />}
                            </>
                        )}

                        {allComments?.length > 0 && (
                            <>
                                <div className="mt-4 d-flex gap-20">
                                    <img crossOrigin="anonymous" src={Chat2Icon}></img>
                                    <div className="comment-title"> {selectedProject?.project_comments}&nbsp; Comments</div>
                                </div>

                                <div className="project-container mt-4 project-comment-show" style={{ padding: "10px 10px" }}>
                                    <ShowAllComments
                                        selectedProjectId={impactId}
                                        allComments={allComments}
                                        selectedProject={selectedProject}
                                        getAllCommetsIdData={getAllCommetsIdData}
                                        allProjectsIdData={allProjectsIdData}
                                    />
                                </div>
                            </>
                        )}
                        <div className=" row mt-4 mb-3 py-0 project-container">
                            <AddComments selectedProjectId={impactId} getAllCommetsIdData={getAllCommetsIdData} allProjectsIdData={allProjectsIdData} />
                        </div>
                    </div>
                </>
            )}
            {showAddModal && (
                <EditMoreProjects
                    onClose={closeAddModal}
                    selectedProjectId={impactId}
                    projectIdData={projectIdData}
                    allEditProjectsIdData={allEditProjectsIdData}
                    setProjectIdData={setProjectIdData}
                />
            )}
        </>
    );
};

export default ShowProjectsDetails;
