import React from "react";
import "../../auth.css";
import LoginContentForm from "./LoginContentForm";
import logo from "../../../../assets/login/login_logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageContentSection from "../../common/LanguageContentSection";

const LoginContentSection = () => {
    //  states start
    const { t } = useTranslation();
    // states end
    return (
        <>
            {/* login content section start */}
            <div className="login-content-section">
                {/* language selection start */}
                <div className="login-language-section">
                    <LanguageContentSection />
                </div>
                {/* language selection end */}

                {/* login content form start */}
                <div className="login-content-form">
                    <div className="login-catapult-logo">
                        <img crossOrigin="anonymous" src={logo} alt="Website Logo" className="logo-image" />
                    </div>
                    <div className="login-form">
                        <LoginContentForm />
                    </div>
                </div>
                {/* login content form end */}

                {/* login content footer start */}
                <div className="login-footer">
                    <span>{t("COMMON.FORGOT.PASSWORD")}?</span>
                    <Link to="/forgot-password">{t("COMMON.RESET.NOW")}</Link>
                </div>
                {/* login content footer end */}
            </div>
            {/* login content section end */}
        </>
    );
};

export default LoginContentSection;
