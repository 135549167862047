import React, { useEffect, useState } from "react";
import { notificationService } from "../../services/notificationService/NotificationServices";
import convertCreatedAtToTimeAgo from "../../utils/common-functions/ConvertCreatedAtToTimeAgo";
import "./notification.css";
import ArrowBack from "../../assets/arrow-back.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import notFound from "../../assets/not-found/not_found.png";
import Loader from "../../components/common/Loader";
import Pagination from "@mui/material/Pagination";
import { RiCheckboxBlankFill } from "react-icons/ri";

const Notification = () => {
    const [allNotifications, setAllNotifications] = useState([]);
    const [displayedNotifications, setDisplayedNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const allNotificationsData = () => {
        setIsLoading(true);
        notificationService.getNotification().then((response) => {
            setAllNotifications(response?.data?.data);
            setDisplayedNotifications(response?.data?.data?.slice(0, pageSize));
            setIsLoading(false);
        });
    };

    useEffect(() => {
        allNotificationsData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const startIndex = (newPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        setDisplayedNotifications(allNotifications.slice(startIndex, endIndex));
        scrollToTop();
    };

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxChange = (notificationId) => {
        if (selectedCheckboxes.includes(notificationId)) {
            setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== notificationId));
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, notificationId]);
        }
    };

    const handleSelectAll = () => {
        const allIds = allNotifications.map((notification) => notification.id);
        if (selectedCheckboxes.length === allIds.length) {
            setSelectedCheckboxes([]);
        } else {
            setSelectedCheckboxes(allIds);
        }
    };

    const handleReadNotifications = () => {
        const data = new FormData();
        selectedCheckboxes.forEach((item) => data.append("notificationIds[]", item));
        notificationService.readNotification(data).then((res) => {
            toast.success(res?.data?.message);
            allNotificationsData(page);
            setSelectedCheckboxes([]);
        });
    };

    const handleBack = () => {
        navigate(-1);
        scrollToTop();
    };

    return (
        <>
            <h3 className="d-lg-block d-none">Notifications</h3>
            <div className="outer-card-layout mt-4 p-3">
                <div className="d-flex" style={{ flex: "0 0 10%" }}>
                    <div
                        onClick={() => {
                            handleBack();
                        }}
                    >
                        <button className="btn btn-primary p-0 px-2 d-flex align-items-center">
                            <img crossOrigin="anonymous" src={ArrowBack}></img>
                            Back
                        </button>
                    </div>
                    <div className="row  align-items-center" style={{ flex: "0 0 55%", marginLeft: "10px" }}>
                        <div className="col-6">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#97a3e763" fontSize={20} />
                                <span style={{ fontSize: "12px" }}>Unread Notification</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="rgba(55, 67, 104, 1)" fontSize={20} />
                                <span style={{ fontSize: "12px" }}>Read Notification</span>
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-primary" style={{ flex: "0 0 30%" }} onClick={handleReadNotifications} disabled={selectedCheckboxes.length === 0}>
                        Read Notifications
                    </button>
                </div>

                {isLoading ? (
                    <Loader />
                ) : allNotifications.length > 0 ? (
                    <table className="table noti-table mt-4 p-0">
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" onChange={handleSelectAll} checked={selectedCheckboxes.length === allNotifications.length} />
                                </th>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedNotifications.map((notification) => (
                                <tr key={notification.id} className="table-primary table-row-tr">
                                    <td className={`first-row-td ${notification.is_read === 0 ? "read-notification" : ""}`}>
                                        <input type="checkbox" onChange={() => handleCheckboxChange(notification.id)} checked={selectedCheckboxes.includes(notification.id)} />
                                    </td>
                                    <td className={`second-row-td ${notification.is_read === 0 ? "read-notification" : ""}`}>{notification.notifications.title}</td>
                                    <td className={`third-row-td ${notification.is_read === 0 ? "read-notification" : ""}`}>{notification.notifications.message}</td>
                                    <td className={`four-row-td ${notification.is_read === 0 ? "read-notification" : ""}`}>{convertCreatedAtToTimeAgo(notification.notifications.created_at)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="w-100 shadow p-3 mt-3 rounded-4 text-center">
                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                        <h5>No notifications added</h5>
                        <p>No notifications at the moment.</p>
                    </div>
                )}
            </div>
            {allNotifications?.length > 0 && (
                <div className="notification-pagination-container">
                    <Pagination count={Math.ceil(allNotifications.length / pageSize)} page={page} onChange={handleChangePage} variant="outlined" shape="circular" size="medium" />
                </div>
            )}
        </>
    );
};

export default Notification;
