import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../Style/Schedular.css";
import { CustomToolbar } from "../CustomToolbar";
import AddEventsPopup from "../Modal/AddEventsPopup";
import { schedularService } from "../../../services/schedularCalendarService/SchedularCalendarService";
import convertUtcToSelectedTimeZone from "../../../utils/common-functions/ConvertUtcToTimezone";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import TimezoneSelect from "./TimeZoneSelector";
import "../Style/TimeZone.css";
import { ZOOM_SESSION } from "../../../lib/sessionTypes";
import { useNavigate } from "react-router-dom";
import ShowAllEvents from "../Modal/ShowAllEvents";
import { MEETING_TYPES, ROUTES } from "../../../constants";
import { RiCheckboxBlankFill } from "react-icons/ri";

const localizer = momentLocalizer(moment);
const defaultTZ = "PST8PDT";

const SpeakerScheduleCalendar = ({ setIsLoading, formats, handleViewChange, currentDate, currentView, dayPropGetter }) => {
    // start states
    const [isAddEventPopupOpen, setAddEventPopupOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [flag, setFlag] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [allMeetings, setAllMeetings] = useState([]);
    const [showCustomTooltip, setShowCustomTooltip] = useState(false);
    const [speakerAvailableData, setSpeakerAvailableData] = useState([]);
    const navigate = useNavigate();
    const [newEvent, setNewEvent] = useState({
        title: "",
        start: null,
        end: null,
    });
    const [timezone, setTimezone] = useState(defaultTZ);
    // end states

    // call api for get all availability of Speaker
    const getAllSpeakerAvailabilityData = () => {
        schedularService.getCalendarData().then((response) => {
            setSpeakerAvailableData(response?.data?.calendar);
            setFlag(response?.data?.isMeetingScheduled);
        });
    };

    // useEffect of call all api function
    useEffect(() => {
        getAllSpeakerAvailabilityData();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleSession = (item) => {
        if (item.type === "speaker_availability_meetings") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.speackerMeeting).replace(":id", item?.id));
        } else if (item.type === "previous_keynotes") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", item?.keyNote?.id));
        } else if (item.type === "completed_keynotes") {
            return navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.session).replace(":id", item?.keyNote?.id));
        }

        navigate("/dashboard", {
            state: {
                src: item?.meetingLink,
                sessionType: ZOOM_SESSION,
                sessionStartTime: item?.startTime,
            },
        });
        scrollToTop();
    };

    const handleSpeakerMeetingCancel = (item) => {
        setIsLoading(true);
        schedularService
            .cancelSpeakerScheduleMeeting(item?.id)
            .then((res) => {
                if (res) {
                    toast.success(res?.data?.message);
                    getAllSpeakerAvailabilityData();
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
                setIsLoading(false);
            });
    };

    const handleDeleteSpeakerAvailability = (item) => {
        setIsLoading(true);
        schedularService
            .deleteSpeakerAvailability(item?.id)
            .then((res) => {
                toast.success(res?.data?.msg);
                getAllSpeakerAvailabilityData();
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.msg);
                setIsLoading(false);
            });
    };

    // function for available schedules devided to 30 minutes schedule
    function generateHourlySlots(start, end) {
        const slots = [];
        let current = moment(start);

        while (current.isBefore(end)) {
            const next30Minutes = moment(current).add(30, "minutes");
            if (next30Minutes.isAfter(end)) {
                break;
            }
            slots.push({
                start: convertUtcToSelectedTimeZone(current.toDate()),
                end: convertUtcToSelectedTimeZone(next30Minutes.toDate()),
            });
            current = next30Minutes;
        }
        return slots;
    }

    // function of open popup for added start time and end time
    const handleSelectSlot = ({ start, end }) => {
        const selectedStartTime = moment(start);
        const currentTime = moment();

        if (selectedStartTime.isBefore(currentTime)) {
            toast.error("Selected time is in the past.");
        } else {
            setSelectedDate(selectedStartTime);
            setSelectedSlot({ start, end });
            setAddEventPopupOpen(true);
        }
    };

    // function of close popup for added start time and end time
    const handleCloseAddEventPopup = () => {
        setAddEventPopupOpen(false);
    };

    // === Event 1 === Function to generate events for speaker availability
    const generateSpeakerAvailabilityEvents = () => {
        const events = [];

        speakerAvailableData?.Speaker?.speaker_availability?.available_slots?.map((item) => {
            const startDateTime = convertUtcToSelectedTimeZone(`${item.start_date}`);
            const endDateTime = convertUtcToSelectedTimeZone(`${item.end_date}`);
            // Generate 30-minute slots using generateHourlySlots function
            const slots = generateHourlySlots(startDateTime, endDateTime);

            // Create an event for each 30-minute slot
            slots.forEach((slot) => {
                events.push({
                    id: item.id,
                    title: item?.speaker?.first_name + " " + item?.speaker?.last_name,
                    start: slot.start,
                    end: slot.end,
                    speakerName: item?.speaker?.first_name + " " + item?.speaker?.last_name,
                    type: "speaker_availability",
                });
            });
        });

        return events;
    };

    // === Event 2 === function to generate events for speaker schedule meetings
    const speakerBookingEvents = () => {
        const events = [];

        speakerAvailableData?.Speaker?.speaker_availability?.booked?.map((item) => {
            const startDateTime = convertUtcToSelectedTimeZone(`${item.start_date}`);
            const endDateTime = convertUtcToSelectedTimeZone(`${item.end_date}`);

            events.push({
                id: item.id,
                title: item?.poc?.first_name + " " + item?.poc?.last_name,
                start: new Date(startDateTime),
                end: new Date(endDateTime),
                speakerName: item?.institute_key_notes?.speaker?.first_name + " " + item?.institute_key_notes?.speaker?.last_name,
                keynote: item?.institute_key_notes?.key_note?.name,
                meetingLink: item?.meeting_details?.link,
                type: "speaker_availability_meetings",
                pocName: item?.poc?.first_name + " " + item?.poc?.last_name,
            });
        });

        return events;
    };

    const speakerAvailabilityEvents = generateSpeakerAvailabilityEvents();

    // === Event 3 === Show Previous Session of speaker
    const showPreviousSession = () => {
        const session = speakerAvailableData?.Speaker?.sessions_details;
        const previousKeyNotes = session?.institution_key_notes;

        if (flag) {
            const previousSessionEvents = Array.isArray(previousKeyNotes)
                ? previousKeyNotes.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.key_note?.name,
                          masterClass: item?.key_note?.master_class?.name,
                          type: "previous_keynotes",
                          keyNote: item?.key_note,
                          handleSession: handleSession,
                          meetingDetails: item?.key_note_online_booking?.details,
                          meetingDetailsId: item?.key_note_online_booking_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];

            return previousSessionEvents;
        } else {
            return [];
        }
    };

    const previousSessionEvents = showPreviousSession();

    // === Event 4 === Show Completed session of poc
    const showCompletedSession = () => {
        const session = speakerAvailableData?.Speaker?.sessions_details;
        const completedKeyNotes = session?.completed_key_notes;
        if (flag) {
            const completedSessionEvents = Array.isArray(completedKeyNotes)
                ? completedKeyNotes.map((item) => {
                      const startDate = convertUtcToSelectedTimeZone(`${item?.start_date}`);
                      const endDate = convertUtcToSelectedTimeZone(`${item?.end_date}`);

                      return {
                          id: item?.id,
                          start: startDate,
                          end: endDate,
                          title: item?.key_note?.name,
                          masterClass: item?.key_note?.master_class?.name,
                          type: "completed_keynotes",
                          keyNote: item?.key_note,
                          handleSession: handleSession,
                          meetingDetails: item?.key_note_online_booking?.details,
                          meetingDetailsId: item?.key_note_online_booking_details_id,
                          startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
                          endTime: moment(endDate).format("HH:mm:ss"),
                      };
                  })
                : [];

            return completedSessionEvents;
        } else {
            return [];
        }
    };

    const completedSessionEvents = showCompletedSession();

    // combined all events
    const combinedEvents = [...speakerAvailabilityEvents, ...previousSessionEvents, ...completedSessionEvents, ...speakerBookingEvents()];

    // function for open sweet alert popup
    const handleEventClick = (event) => {
        const { start, end, type, keynote, pocName, title, masterClass } = event;

        const startTime = moment(start).format("hh:mm A");
        const endTime = moment(end).format("hh:mm A");
        const isMeetingEnd = moment().tz(timezone).isAfter(moment(end));

        if (type === "speaker_availability_meetings") {
            Swal.fire({
                title: "Your Meeting Details",
                html: `
                    <p><strong>POC Name:</strong> ${pocName}</p>
                    <p><strong>Keynote:</strong> ${keynote}</p>
                    <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
                    <p><strong>Time:</strong> ${startTime} - ${endTime}</p>
                `,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Join Now",
                showConfirmButton: !isMeetingEnd,
                cancelButtonText: "Close",
                showDenyButton: true,
                denyButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                } else if (result.isDenied) {
                    Swal.fire({
                        title: "Are you sure?",
                        text: "Are you sure want to cancel this meeting?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, cancel it!",
                        cancelButtonText: "No, keep it",
                    }).then((cancelResult) => {
                        if (cancelResult.isConfirmed) {
                            handleSpeakerMeetingCancel(event);
                        }
                    });
                }
            });
        } else if (type === "speaker_availability") {
            Swal.fire({
                title: "Your Availability Details",
                html: `
          <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
          <p><strong>Time:</strong> ${startTime} - ${endTime}</p>
        `,
                icon: "info",
                showCancelButton: true,
                cancelButtonText: "Close",
                showConfirmButton: true,
                confirmButtonText: "Delete",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleDeleteSpeakerAvailability(event);
                }
            });
        } else if (type === "previous_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        } else if (type === "completed_keynotes") {
            Swal.fire({
                title: "Session Details",
                html: `
        <p><strong>Keynote Name:</strong> ${title}</p>
        <p><strong>Masterclass Name:</strong> ${masterClass}</p>
        <p><strong>Date:</strong> ${moment(start).format("LL")}</p>
        <p><strong>Time:</strong> ${startTime} - ${endTime}</p>`,
                icon: "info",
                showCancelButton: true,
                showConfirmButton: !isMeetingEnd,
                confirmButtonText: "Join Now",
                cancelButtonText: "Close",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSession(event);
                }
            });
        }
    };

    // Function to determine the background color of events
    const eventStyleGetter = (event) => {
        if (event.type === "speaker_availability") {
            return {
                style: {
                    backgroundColor: "#8bc34a",
                    color: "black",
                },
            };
        }
        if (event.type === "speaker_availability_meetings") {
            return {
                style: {
                    backgroundColor: "#fec107",
                    color: "black",
                },
            };
        } else if (event.type === "previous_keynotes") {
            return {
                style: {
                    backgroundColor: "#03a9f3",
                    color: "black",
                    marginTop: "25px",
                },
            };
        } else if (event.type === "completed_keynotes") {
            return {
                style: {
                    backgroundColor: "#4CCABA",
                    color: "black",
                },
            };
        }

        return {};
    };

    const { scrollToTime } = useMemo(() => {
        moment.tz.setDefault(timezone);
        return {};
    }, [timezone]);

    const handleShowMoreClick = (events, date, view) => {
        setShowAll(true);
        setAllMeetings(events);
        setShowCustomTooltip(true);
    };
    const closeCustomTooltip = () => {
        setShowCustomTooltip(false);
        setShowAll(false);
    };
    return (
        <>
            <h3 className="d-lg-block d-none ">Calendar</h3>
            <div className="card-layout schedular-section mt-4">
                <div className="calendar-container">
                    <div className="row m-0 py-3 px-4">
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#4CCABA" fontSize={12} />
                                <span style={{ fontSize: "12px" }}>Completed Keynotes</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#03a9f3" fontSize={12} />

                                <span style={{ fontSize: "12px" }}> Upcoming Keynotes</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#8bc34a" fontSize={12} />

                                <span style={{ fontSize: "12px" }}>Speaker Availability</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex gap-1 justify-content-start align-items-center">
                                <RiCheckboxBlankFill color="#fec107" fontSize={12} />
                                <span style={{ fontSize: "12px" }}> Speaker & POC Meeting</span>
                            </div>
                        </div>
                    </div>
                    <div className="rbc-toolbar justify-content-start gap-3" style={{ padding: "20px" }}>
                        <span className="fs-6 fw-bold">Select Time Zone :</span>
                        <TimezoneSelect defaultTZ={defaultTZ} setTimezone={setTimezone} timezone={timezone} />
                    </div>
                    <Calendar
                        components={{
                            toolbar: (props) => <CustomToolbar {...props} onViewChange={handleViewChange} currentView={currentView} />,
                        }}
                        localizer={localizer}
                        formats={formats}
                        events={combinedEvents}
                        view={currentView}
                        date={currentDate.toDate()}
                        style={{ height: 1260, color: "#fff", backgroundColor: "#374368" }}
                        startAccessor="start"
                        endAccessor="end"
                        dayPropGetter={dayPropGetter}
                        selectable="ignoreEvents"
                        eventPropGetter={eventStyleGetter}
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleEventClick}
                        onShowMore={handleShowMoreClick}
                        scrollToTime={scrollToTime}
                    />
                </div>
            </div>
            {isAddEventPopupOpen && (
                <AddEventsPopup
                    add={isAddEventPopupOpen}
                    onClose={handleCloseAddEventPopup}
                    setNewEvent={setNewEvent}
                    selectedDate={selectedDate}
                    selectedSlot={selectedSlot}
                    newEvent={newEvent}
                    getAllSpeakerAvailabilityData={getAllSpeakerAvailabilityData}
                />
            )}
            {showCustomTooltip && <ShowAllEvents showAll={showAll} events={allMeetings} onClose={closeCustomTooltip} handleEventClick={handleEventClick} />}
        </>
    );
};

export default SpeakerScheduleCalendar;
