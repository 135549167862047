import notFound from "../../assets/not-found/not_found.png";

export default function NoSessionsAvailable() {
    return (
        <div className="card-layout mt-4">
            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={400} />
                <h4>No Keynotes Added</h4>
                <p className="fs-6">No Keynotes available at the moment.</p>
            </div>
        </div>
    );
}
