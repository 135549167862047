import apiClient from "../../utils/apiClient";

const GET_DASHBOARD_SESSION = "/get-dashboard-session";
const FETCH_OTHER_MEETING_DETAILS = "/other-meeting-details";
const GET_MILESTONE = "/get-milestones";
const UPDATED_KEYNOTE_ATTENDANCE = "add-keynote-attendance";
const GET_PROGRESS_BAR = "/get-masterclass-progress";

const getDashboardSession = (id, isPreRecordedSession) => {
    if (!isPreRecordedSession) {
        let url = GET_DASHBOARD_SESSION + `?key_note_id=${id}`;
        return apiClient.get(url);
    } else if (!isPreRecordedSession && id === "") {
        return apiClient.get(GET_DASHBOARD_SESSION);
    } else if (isPreRecordedSession) {
        let url = GET_DASHBOARD_SESSION + `?playbook_question_id=${id}`;
        return apiClient.get(url);
    }
};

const getOtherMeetingDetails = (request_type, id) => {
    let url = FETCH_OTHER_MEETING_DETAILS + `?request_type=${request_type}&id=${id}`;
    return apiClient.get(url);
};

const getMilestone = () => {
    return apiClient.get(GET_MILESTONE);
};

const getProgressBar = () => {
    return apiClient.get(GET_PROGRESS_BAR);
};

const updatedKeynoteAttendance = (id, percentage, duration) => {
    const data = {
        institute_key_notes_id: id,
        percentage: percentage,
        total_duration: duration,
    };
    const url = `${UPDATED_KEYNOTE_ATTENDANCE}`;
    return apiClient.post(url, data);
};

export const DashboardService = {
    getDashboardSession,
    getMilestone,
    getOtherMeetingDetails,
    updatedKeynoteAttendance,
    getProgressBar,
};
