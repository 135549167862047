import React, { useEffect, useState } from "react";
import VideoTrackerPercentage from "../../../utils/common-functions/VideoTrackerPercentage";

const PreRecordedVideo = ({ videoUrl, id, updateQuestionAttendance }) => {
    const { videoRef, percentageWatched } = VideoTrackerPercentage();

    const [attendanceUpdated, setAttendanceUpdated] = useState(false);

    useEffect(() => {
        // Check if the attendance has already been updated
        if (!attendanceUpdated && percentageWatched.toFixed(2) >= 90.0) {
            // Call the function to update attendance
            updateQuestionAttendance();

            // Set the state to indicate that attendance has been updated
            setAttendanceUpdated(true);
        }
    }, [percentageWatched, id, attendanceUpdated]);

    return (
        <>
            <div>
                <video ref={videoRef} crossOrigin="anonymous" controls className="img-fluid">
                    <source crossOrigin="anonymous" src={videoUrl} />
                </video>
            </div>
        </>
    );
};

export default PreRecordedVideo;
