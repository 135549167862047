import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { todoService } from "../../../services/todoService/todoService";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const AddTodoList = ({ showModal, handleClose, getTodoList }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const validationSchema = yup.object({
        title: yup
            .string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .required("Task Name is required"),
        date: yup.date().required("Task Date is required"),
    });

    const formik = useFormik({
        initialValues: {
            description: "",
            date: null,
            title: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsButtonDisabled(false);
            try {
                const formattedDate = moment(values.date).format("YYYY-MM-DD");
                const res = await todoService.addTodo({
                    ...values,
                    date: formattedDate,
                });
                setIsButtonDisabled(true);
                handleClose();
                getTodoList();
                toast.success(res?.data?.message);
            } catch (error) {
                console.error("Error adding task:", error);
            }
        },
    });

    return (
        <Modal show={showModal} onHide={handleClose} className="add-todo-modal">
            <Modal.Header closeButton>
                <Modal.Title>Add To Do</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit} className="add-todo-form">
                    <Form.Group controlId="title" className="add-todo-name">
                        <Form.Label className="question">Task Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter task name"
                            name="title"
                            className="form-control"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            isInvalid={formik.touched.title && formik.errors.title}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="description" className="add-todo-name mt-3">
                        <Form.Label className="question">Task Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter task description"
                            name="description"
                            value={formik.values.description}
                            className="form-control todo-list-decription"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            rows={5}
                            cols={50}
                            style={{ resize: "none" }}
                            isInvalid={formik.touched.description && formik.errors.description}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="date" className="add-todo-name mt-3 d-flex flex-column">
                        <Form.Label className="question">Task Date</Form.Label>
                        <DatePicker
                            placeholderText="Enter task date"
                            name="selectedDate"
                            id="selectedDate"
                            autoComplete="off"
                            selected={formik.values.date}
                            onChange={(date) => formik.setFieldValue("date", date)}
                            dateFormat="dd-MM-yyyy"
                            className={`form-control position-relative ${formik.errors.date && formik.touched.date ? "is-invalid" : ""}`}
                            placeholder="Enter task date"
                            minDate={new Date()}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.date}</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="add-todo-btn" disabled={!isButtonDisabled}>
                        Add Task
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddTodoList;
