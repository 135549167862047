import React, { useState } from "react";
import AddPlus from "../../../assets/Add_Plus.svg";
import "../community.css";
import ShowAllDiscussionPosts from "../../playbook-mini-courses/components/ShowAllDiscussionPosts";
import AddPost from "../../playbook-mini-courses/modal/add-post";
const DiscussionAccordion = ({ question, handleAddPostData, getCommunity }) => {
    const [showAddModal, setShowAddModal] = useState(false);

    const handleAddPost = () => {
        setShowAddModal(true);
    };

    const closeAddPost = (data) => {
        setShowAddModal(false);
        handleAddPostData(question.id, data);
    };

    return (
        <>
            <div className="accordion-container mt-2">
                <div className="accordion-item">
                    <div>
                        <div className="d-flex mt-2 justify-content-between accr">
                            <div className="accordion-title">{question.question}</div>

                            <div className="add-post cursor-pointer" onClick={handleAddPost}>
                                <img crossOrigin="anonymous" src={AddPlus} style={{ marginRight: "10px" }}></img>
                                Reply to Post
                            </div>
                        </div>
                    </div>
                    <ShowAllDiscussionPosts communityDiscussion={question.community_discussion} handleAddPostData={handleAddPostData} getCommunity={getCommunity} />
                </div>
            </div>
            {showAddModal && <AddPost onClose={closeAddPost} questionId={question.id} getCommunity={getCommunity} />}
        </>
    );
};
export default DiscussionAccordion;
