import apiClient from "../../utils/apiClient";

const LOGIN = "/login";

const login = (data) => {
  const url = `${LOGIN}`;
  return apiClient.post(url, data);
};

export const loginService = {
  login,
};
