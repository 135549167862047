import apiClient from "../../utils/apiClient";

const GET_PLAYBOOK_COURSE = "/get-play-book-course";
const GET_KEY_NOTES = "/get-key-notes";
const ADD_POST = "add-post";
const GET_DISCUSSION = "get-post";
const GET_LIKE = "discussion-like";
const UPDATED_QUESTION_ATTENDANCE = "add-playbook-question-attendance";
const GET_PLAYBOOK_DISCUSSIONS_BY_ID = "get-discussion";
const EDIT_PLAYBOOK_DISCUSSIONS = "edit-community-discussion";
const DELETE_PLAYBOOK_DISCUSSIONS = "delete-community-discussion";

const getPlaybookCourses = (keyNote) => {
    let url = `${GET_PLAYBOOK_COURSE}`;
    if (keyNote != 0) {
        url = `${GET_PLAYBOOK_COURSE}?key_notes_id=${keyNote}`;
    }
    return apiClient.get(url);
};

const getKeyNoteList = () => {
    const url = `${GET_KEY_NOTES}`;
    return apiClient.get(url);
};

const addPost = (data) => {
    const url = `${ADD_POST}`;
    return apiClient.post(url, data);
};

const getDiscussion = (id) => {
    const url = `${GET_DISCUSSION}?play_book_course_question_id=${id}&order=desc`;
    return apiClient.get(url);
};

const addLike = (id, isCommunity = true) => {
    const url = `${GET_LIKE}`;
    if (isCommunity) {
        return apiClient.post(url, {
            community_discussion_id: id,
        });
    } else {
        return apiClient.post(url, {
            general_community_discussion_id: id,
        });
    }
};

const updatedQuestionAttendance = (id) => {
    const url = `${UPDATED_QUESTION_ATTENDANCE}`;
    return apiClient.post(url, id);
};

const getPlaybookDiscussionById = (id) => {
    const url = `${GET_PLAYBOOK_DISCUSSIONS_BY_ID}/${id}`;
    return apiClient.get(url);
};

const editPlaybookDiscussion = (data) => {
    const url = `${EDIT_PLAYBOOK_DISCUSSIONS}`;
    return apiClient.post(url, data);
};

const deletePlaybookDiscussion = (id) => {
    const data = {
        community_discussion_id: id,
    };
    const url = `${DELETE_PLAYBOOK_DISCUSSIONS}`;
    return apiClient.post(url, data);
};

export const miniBookService = {
    getPlaybookCourses,
    getKeyNoteList,
    addPost,
    getDiscussion,
    addLike,
    updatedQuestionAttendance,
    getPlaybookDiscussionById,
    editPlaybookDiscussion,
    deletePlaybookDiscussion,
};
