import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import i18n from "../src/lib/languages/i18n";
import "./index.css";
import App from "./App";
import { store, persistor } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
const { PUBLIC_URL } = process.env;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <I18nextProvider i18n={i18n}>
                <BrowserRouter basename={PUBLIC_URL}>
                    <App />
                </BrowserRouter>
            </I18nextProvider>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);

reportWebVitals();
