import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./ProgressBar.css";
import Pro from "../../assets/progressbarIcons/pro.svg";
import Noob from "../../assets/progressbarIcons/noob.svg";
import Rookie from "../../assets/progressbarIcons/rookie.png";
import { DashboardService } from "../../services/dashboardService/dashboardService";
import { useDispatch } from "react-redux";
import { setProgressBar } from "../../redux/authSlice";
import { useSelector } from "react-redux";

const MasterclassProgressBar = () => {
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const [progressCount, setProgressCount] = useState(0);
    const dispatch = useDispatch();

    const getProgressBarCount = () => {
        DashboardService.getProgressBar().then((res) => {
            setProgressCount(res?.data?.data?.total_progress);
            dispatch(setProgressBar(res?.data?.data?.total_progress));
        });
    };

    useEffect(() => {
        if (userType !== 4) {
            getProgressBarCount();
        }
    }, []);

    return (
        <div className="masterclass-progress">
            {userType !== 4 && (
                <>
                    <div className="my-3 fw-bold">Masterclass Progress</div>
                    <div className="d-flex justify-content-between align-items-center gap-2">
                        <ProgressBar completed={progressCount} bgColor="rgba(255, 210, 0, 1)" baseBgColor="#fff" className="w-100 progressbar-count" />
                        {progressCount <= 30 ? (
                            <img crossOrigin="anonymous" src={Noob} alt="" />
                        ) : progressCount <= 60 ? (
                            <img crossOrigin="anonymous" src={Rookie} alt="" />
                        ) : (
                            <img crossOrigin="anonymous" src={Pro} alt="" />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default MasterclassProgressBar;
