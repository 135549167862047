import React from "react";
import "../../auth.css";
import logo from "../../../../assets/login/login_logo.png";
import LanguageContentSection from "../../common/LanguageContentSection";
import OtpVerifyContentForm from "./OtpVerifyContentForm";

const OtpVerifyContentSection = () => {
    return (
        <>
            {/* Otp Verification content section start */}
            <div className="login-content-section">
                {/* language selection start */}
                <div className="login-language-section">
                    <LanguageContentSection />
                </div>
                {/* language selection end */}

                {/* Otp Verification content form start */}
                <div className="login-content-form  mt-4 ">
                    <div className="login-catapult-logo">
                        <img src={logo} alt="Website Logo" className="logo-image" />
                    </div>
                    <div className="login-form otp-verification-form">
                        <OtpVerifyContentForm />
                    </div>
                </div>
                {/* Otp Verification content form end */}
            </div>
            {/* Otp Verification content section end */}
        </>
    );
};

export default OtpVerifyContentSection;
