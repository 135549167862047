import React from "react";
import appStore from "../../assets/download-app/App Store.png";
import googlePay from "../../assets/download-app/Google Play.png";

const DownloadApp = () => {
    return (
        <>
            <h3 className="d-lg-block d-none">Download App</h3>
            <div className="card-layout d-flex gap-4" style={{ height: "200px" }}>
                <div className="d-flex gap-4 align-items-center justify-content-center w-100">
                    <div className="d-flex gap-4 flex-column flex-lg-row flex-md-row align-items-center ">
                        <div>
                            <img src={googlePay} alt="" className="" />
                        </div>
                        <div>
                            <img src={appStore} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DownloadApp;
