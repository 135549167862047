import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import notFound from "../../assets/not-found/not_found.png";
import "./TodoList.css";
import { FiCalendar } from "react-icons/fi";
import { todoService } from "../../services/todoService/todoService";
import Loader from "../common/Loader";
import AddTodoList from "./modal/AddTodoList";
import EditTodoList from "./modal/EditTodoList";
import { toast } from "react-toastify";

const TodoList = () => {
    const [todoList, setTodoList] = useState([]);
    const [todosLoading, setTodosLoading] = useState(true);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [todo, setTodo] = useState({});
    const [index, setIndex] = useState(0);

    useEffect(() => {
        getTodoList();
    }, []);

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowEditModal = (todo, index) => {
        setTodo(todo);
        setIndex(index);
        setShowEditModal(true);
    };
    const handleCloseEditModal = () => setShowEditModal(false);

    // Get all todos
    const getTodoList = async () => {
        try {
            const list = await todoService.getTodoList();
            setTodoList(list?.data?.data?.reverse());
            setTodosLoading(false);
        } catch (error) {
            setTodosLoading(false);
        }
    };

    const deleteTodo = (id) => {
        Swal.fire({
            title: "Confirm to delete",
            text: "Are you sure you want to delete todo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    todoService.deleteTodo(id).then((res) => {
                        toast.success(res?.data?.message);
                        setTodoList((prevList) => {
                            return prevList.filter((todo) => todo.id !== id);
                        });
                    });
                } catch (error) {
                    console.error(error);
                }
            } else {
            }
        });
    };

    // Change todo status to either "Pending" or "Completed"
    const markAsComplete = async (id, status) => {
        const updatedTodoList = todoList.map((todo) => {
            if (todo.id === id) {
                if (todo.status === "Pending") {
                    return {
                        ...todo,
                        status: "Completed",
                    };
                } else if (todo.status === "Completed") {
                    return {
                        ...todo,
                        status: "Pending",
                    };
                }
            } else {
                return todo;
            }
        });

        setTodoList(updatedTodoList);

        let data;
        if (status === "Completed") {
            data = { status: "Pending" };
        } else {
            data = { status: "Completed" };
        }
        try {
            await todoService.changeTodoStatus(id, data);
        } catch (error) {}
    };

    const isLink = (text) => {
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(text.trim());
    };

    return (
        <div className="d-flex flex-column justify-content-lg-start align-items-lg-start">
            <div className="card-layout-header">
                <h5>My To Do list</h5>
            </div>

            <div className={`todo-body ${todoList?.length > 0 ? "mt-2" : "overflow-hidden p-0"}`}>
                {todosLoading ? (
                    <Loader />
                ) : todoList.length > 0 ? (
                    todoList.map((todo, index) => {
                        return (
                            <div className="d-flex gap-3 justify-content-between todo-item pt-3" key={todo.id}>
                                <div className="todo-checkbox d-flex">
                                    <input type="checkbox" id={`${todo?.id}`} checked={todo?.status === "Completed"} onChange={() => markAsComplete(todo?.id, todo?.status)} />
                                    <label htmlFor={`${todo?.id}`} className="todo-checkbox-label"></label>
                                    <label className="d-flex justify-content-start align-items-start">
                                        <div className={`d-flex flex-column gap-1 ${todo?.status === "Completed" && "completed-todo-item"}`}>
                                            <div className={`${!todo?.title?.includes(" ") && "no-space-text"}`}>{todo?.title}</div>
                                            <p className="p-0 m-0 todo-description">
                                                {todo?.description?.split(/\s+/).map((item, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {isLink(item) ? (
                                                            <>
                                                                <a href={item} target="_blank" rel="noopener noreferrer">
                                                                    {item}
                                                                </a>
                                                                {index < array.length - 1 && " "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span>{item}</span>
                                                                {index < array.length - 1 && " "}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                            <div className="todo-date gap-2">
                                                <FiCalendar />
                                                <span>{moment(todo?.date).format("Do MMM YYYY")}</span>
                                            </div>
                                            <div className="d-flex"></div>
                                        </div>
                                    </label>
                                </div>

                                {!todo?.created_by_admin && (
                                    <div className="fs-6 d-flex" style={{ cursor: "pointer" }}>
                                        {todo.status === "Pending" && <MdEdit style={{ color: "rgba(255, 210, 0, 1)" }} onClick={() => handleShowEditModal(todo, index)} />}
                                        <MdDelete style={{ color: "rgba(255, 148, 148, 1)" }} onClick={() => deleteTodo(todo.id)} />
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <>
                        <div className="w-100 mt-3 rounded-4 text-center">
                            <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                            <h5>No To Do List Added</h5>
                            <p>No To Do list available at the moment.</p>
                        </div>
                    </>
                )}
            </div>

            <div className="todo-add-button mt-4" onClick={handleShowAddModal}>
                + Add
            </div>

            {showAddModal && <AddTodoList showModal={showAddModal} handleClose={handleCloseAddModal} getTodoList={getTodoList} />}

            {showEditModal && <EditTodoList showModal={showEditModal} handleClose={handleCloseEditModal} getTodoList={getTodoList} todo={todo} index={index} />}
        </div>
    );
};

export default TodoList;
