import apiClient from "../../utils/apiClient";

const GET_TOTAL_REGISTRANTS = "/get-total-registrants";
const GET_TOTAL_REGISTRANTS_ENGAGED = "/get-total-registrants-engaged";
const GET_TOTAL_USER_ATTENDANCE_EVENTS = "/get-total-user-attendance-events";
const GET_KEYNOTE_LIST = "/get-keynote-list";
const GET_TOTAL_OPTED_OUT_STUDENTS = "/get-total-opted-out-students";

const getTotalRegistrants = () => {
    const url = `${GET_TOTAL_REGISTRANTS}`;
    return apiClient.get(url);
};

const getTotalRegistrantsEngaged = (date) => {
    const url = `${GET_TOTAL_REGISTRANTS_ENGAGED}?date=${date}`;
    return apiClient.get(url, date);
};

const getTotalUserAttendanceEvents = (keynote_id, live_attended, timezone) => {
    const data = {
        keynote_id: keynote_id,
        live_attended: live_attended,
        timezone: timezone,
    };
    const url = `${GET_TOTAL_USER_ATTENDANCE_EVENTS}?keynote_id=${keynote_id}&live_attended=${live_attended}&timezone=${timezone}`;
    return apiClient.get(url, data);
};

const getKeynoteList = () => {
    const url = `${GET_KEYNOTE_LIST}`;
    return apiClient.get(url);
};

const getTotalOptedOutStudents = (keynote_id, attended) => {
    const data = {
        keynote_id: keynote_id,
        attended: attended,
    };
    const url = `${GET_TOTAL_OPTED_OUT_STUDENTS}?keynote_id=${keynote_id}&attended=${attended}`;
    return apiClient.get(url, data);
};
export const reportServices = {
    getTotalRegistrants,
    getTotalRegistrantsEngaged,
    getTotalUserAttendanceEvents,
    getKeynoteList,
    getTotalOptedOutStudents,
};
