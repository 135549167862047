import React, { useEffect, useState } from "react";

import "./sessions.css";
import { getSessionsService } from "../../services/sessionsService/sessionsService";
import Loader from "../../components/common/Loader";
import UpcomingSession from "./components/Sessions/UpcomingSession";
import LockedSessions from "./components/Sessions/LockedSessions";
import UnlockedSessions from "./components/Sessions/UnlockedSessions";
import notFound from "../../assets/not-found/not_found.png";

const Session = ({ setIsPreviousSession, isPreviousSession }) => {
    const [completedSessions, setCompletedSessions] = useState([]);
    // state for on-going or upcoming sessions
    const [institutionKeyNotes, setInstitutionKeyNotes] = useState([]);
    const [upcomingKeyNoteSessions, setUpcomingKeyNoteSessions] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        getAllSessions();
    }, []);

    const getAllSessions = async () => {
        try {
            const response = await getSessionsService.getSessions();

            setFlag(response?.data?.data?.isMeetingScheduled);
            // Sets the completed sessions states
            if (response?.data?.data?.completed_key_notes !== null) {
                setCompletedSessions(response?.data?.data?.completed_key_notes);
            }

            // Sets the upcomingKeyNoteSessions and institutionKeyNotes
            if (response?.data?.data?.institution_key_notes !== null) {
                // filters out sessions which are not today
                const keyNotes = response?.data?.data?.institution_key_notes.filter((keyNote) => {
                    return keyNote.start_date > new Date().toISOString().substring(0, 10);
                });
                setInstitutionKeyNotes(keyNotes);

                // filter upcoming sessions which are today by time
                const now = new Date();
                const currentDateTime = now.getTime();

                let closestEvent = null;
                let closestTimeDiff = Infinity;

                const todaysEvents = response?.data?.data?.institution_key_notes?.filter((keyNote) => {
                    return keyNote.start_date === new Date().toISOString().substring(0, 10);
                });

                for (const event of todaysEvents) {
                    const eventDateTime = new Date(`${event.date}T${event.start_time}`).getTime();
                    const timeDiff = eventDateTime - currentDateTime;

                    // Exclude keynotes where start_date is the current date and current time is greater
                    if (timeDiff > 0 && timeDiff < closestTimeDiff) {
                        closestEvent = event;
                        closestTimeDiff = timeDiff;
                    }
                }

                if (closestEvent !== null) {
                    setUpcomingKeyNoteSessions(closestEvent);
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h3 className="d-lg-block d-none">Keynotes</h3>
            <div className="outer-card-layout mt-4">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        {upcomingKeyNoteSessions && <UpcomingSession upcomingKeyNoteSessions={upcomingKeyNoteSessions} setIsPreviousSession={setIsPreviousSession} />}
                        {institutionKeyNotes.length > 0 && <LockedSessions institutionKeyNotes={institutionKeyNotes} flag={flag} />}

                        {completedSessions.length > 0 && (
                            <>
                                <div className="mt-4 fw-bolder fs-5">Previous Keynotes</div>
                                <UnlockedSessions completedSessions={completedSessions} flag={flag} setIsPreviousSession={setIsPreviousSession} />
                            </>
                        )}
                        {completedSessions?.length === 0 && institutionKeyNotes?.length === 0 && !upcomingKeyNoteSessions && (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h3>No Keynotes Added</h3>
                                <p className="fs-5">No keynots available at the moment.</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Session;
