/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { toast } from "react-toastify";
import avatarImage from "../../assets/profile-settings/avatarImage.jpg";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ImageUploadedLoader from "../../components/common/ImageUploadedLoader";

const ProfileImageUpdated = ({ getUserData, getUserAllData }) => {
    // states start
    const loginUserData = useSelector((state) => state?.auth?.userData?.full_profile_image);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(loginUserData === null);
    // states end

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const maxSizeInBytes = 10 * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                toast.error("File size exceeds the maximum limit of 10MB. Please select a smaller file.");
                return;
            }

            if (!isValidFileFormat(file)) {
                toast.error("Invalid file format. Please select a .png, .jpg, or .jpeg file.");
                return;
            }

            setSelectedFile(file);
            uploadProfilePicture(file);
        }
    };

    const isValidFileFormat = (file) => {
        const validExtensions = [".png", ".jpg", ".jpeg"];
        const fileName = file.name.toLowerCase();

        return validExtensions.some((extension) => fileName.endsWith(extension));
    };

    const handleDeletePicture = () => {
        setIsLoader(false);
        Swal.fire({
            title: "Confirm to delete",
            text: "Are you sure you want to delete your profile picture?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                setSelectedFile(null);
                uploadProfilePicture(null);
                setIsLoader(false);
            } else {
            }
        });
    };

    const uploadProfilePicture = async (selectedFile) => {
        setIsDeleteButtonDisabled(true);
        setIsLoader(true);
        try {
            const formData = new FormData();
            if (selectedFile !== null) {
                formData.append("profile_image", selectedFile);
            } else {
                formData.append("profile_image", "");
            }
            formData.append("_method", "PUT");

            await profileSettingService.updatedProfileImage(formData);

            getUserAllData();
            setIsLoader(false);
            setIsDeleteButtonDisabled(false);
            toast.success("Your profile has been updated successfully");
        } catch (error) {
            console.error("API error:", error);
        }
    };

    return (
        <>
            <form className="profile-setting-form">
                <div className=" profile-image-container">
                    <div className="profile-image mb-3">
                        <figure style={{ position: "relative", overflow: "hidden" }}>
                            {isLoader && (
                                <div className="profile-image-loader">
                                    <ImageUploadedLoader />
                                </div>
                            )}
                            <img
                                crossOrigin="anonymous"
                                className="img-fluid"
                                src={selectedFile ? URL.createObjectURL(selectedFile) : getUserData?.full_profile_image ? getUserData?.full_profile_image : avatarImage}
                                alt="Profile Image"
                            />
                        </figure>
                        <input key={selectedFile} type="file" name="profile_image" id="profilePictureInput" accept=".png, .jpg, .jpeg" style={{ display: "none" }} onChange={handleFileChange} />
                    </div>
                    <div className="profile-img-btn mb-3">
                        <label htmlFor="profilePictureInput" className="btn change-btn">
                            Change picture
                        </label>
                        <label type="submit" className={`btn delete-btn ${loginUserData === null || isDeleteButtonDisabled ? "disabled" : ""}`} onClick={handleDeletePicture}>
                            Delete picture
                        </label>
                    </div>
                </div>
            </form>
        </>
    );
};

export default ProfileImageUpdated;
