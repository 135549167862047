import React, { useEffect, useState } from "react";

import ResourceCard from "./ResourceCard";
import "./Resources.css";
import { useTranslation } from "react-i18next";
import { MdDriveFolderUpload } from "react-icons/md";
import FileUploadedPopup from "./Modal/FileUploadedPopup";
import { useSelector } from "react-redux";
import { ResourcesService } from "../../services/resourcesService/resourcesServices";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";

const Resources = () => {
    const { t } = useTranslation();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const [showResource, setShowResource] = useState(true);

    const [resourceData, setResourceData] = useState([]);

    const getAllResourcesData = () => {
        ResourcesService.getAllResources().then((res) => {
            setResourceData(res?.data?.data);
        });
    };

    useEffect(() => {
        getAllResourcesData();
    }, []);

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };

    const handleClose = () => {
        setPopupOpen(false);
    };

    const toggleCalendar = () => {
        setShowResource(!showResource);
    };

    return (
        <div className="d-flex flex-column ">
            <div className="card-layout-header d-flex justify-content-between" onClick={toggleCalendar} style={{ cursor: "pointer" }}>
                <h5>{t("DASHBOARD.TITLE.RESOURCES")}</h5>
                {showResource ? (
                    <div className="discussion-toggle">
                        <PiCaretUpBold className="mt-1 fs-5" />
                    </div>
                ) : (
                    <div className="discussion-toggle">
                        <PiCaretDownBold className="mt-1 fs-5" />
                    </div>
                )}
            </div>
            {showResource && (
                <div className={`${userType === 4 ? "mt-4" : "mt-1"} w-100`}>
                    {userType === 4 && (
                        <>
                            <div className="resources-uploaded" onClick={togglePopup}>
                                <MdDriveFolderUpload className="resources-uploaded-icon" />
                                <span className="resources-uploaded-text">Upload Class Notes</span>
                            </div>
                        </>
                    )}

                    <div className={`${resourceData?.length > 0 ? "resources-card-body mt-1 " : "overflow-hidden"}`}>
                        <ResourceCard resourceData={resourceData} />
                    </div>
                </div>
            )}
            {isPopupOpen && <FileUploadedPopup handleClose={handleClose} getAllResourcesData={getAllResourcesData} />}
        </div>
    );
};

export default Resources;
