import React from "react";
import { miniBookService } from "../../../services/miniBookService/miniBookService";
import { DashboardService } from "../../../services/dashboardService/dashboardService";
import PreRecordedVideo from "./PreRecordedVideo";
import YoutubeVideo from "./YoutubeVideo";

const MiniCoursesQuestions = ({ playBookQuestion, key }) => {
    const handleUpdateQuestionAttendance = () => {
        miniBookService.updatedQuestionAttendance({ play_book_course_questions_id: playBookQuestion?.id }).then((res) => {
            DashboardService.getMilestone();
        });
    };

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    return (
        <>
            {(playBookQuestion.full_video_path !== null || playBookQuestion.video_url !== null) && playBookQuestion.description !== null && (
                <div className={key == 0 ? "mt-2 card-layout p-3 questions-layout" : "card-layout questions-layout mt-2"}>
                    <div className="d-flex justify-content-between flex-lg-row flex-column gap-1">
                        <div className="question-title">{playBookQuestion.question}</div>
                    </div>
                    <div className={`d-flex justify-content-between flex-lg-row flex-column gap-1 ${playBookQuestion.description === null ? "" : "mt-4"} `}>
                        <p className="question-description">{renderAnswer(playBookQuestion.description)}</p>
                    </div>
                    {playBookQuestion.full_video_path === null && playBookQuestion.video_url === null ? (
                        ""
                    ) : (
                        <div className={`d-flex justify-content-between flex-lg-row flex-column gap-1 ${playBookQuestion.description === null ? "" : "mt-4"}  w-100`}>
                            <div className="position-relative text-center d-flex justify-content-center align-items-center w-100">
                                {playBookQuestion.full_video_path ? (
                                    <PreRecordedVideo videoUrl={playBookQuestion.full_video_path} id={playBookQuestion?.id} updateQuestionAttendance={handleUpdateQuestionAttendance} />
                                ) : (
                                    <YoutubeVideo
                                        videoUrl={playBookQuestion.video_url}
                                        id={playBookQuestion?.id}
                                        updateQuestionAttendance={() => handleUpdateQuestionAttendance(playBookQuestion.id)}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default MiniCoursesQuestions;
