import apiClient from "../../utils/apiClient";

const VERIFY_OTP_DATA = "/verify_forgot_password_OTP";

const addVerifyOtpData = (data) => {
  const url = `${VERIFY_OTP_DATA}`;
  return apiClient.post(url, data);
};

export const verifyOtpService = {
  addVerifyOtpData,
};
