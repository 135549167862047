import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsCloudArrowDown } from "react-icons/bs";
import notFound from "../../assets/not-found/not_found.png";
import { toast } from "react-toastify";
import { ResourcesService } from "../../services/resourcesService/resourcesServices";

const ResourceCard = ({ resourceData }) => {
    const handleDownload = async (item) => {
        try {
            const res = await ResourcesService.getResorceUrl(item.id);
            const resorceUrl = res?.data?.link;
            const response = await fetch(resorceUrl);
            const blob = await response.blob();
            const filename = (item?.name || "resource_file").replace(/[^\w\s]/g, "_").replace(/\s+/g, "_");
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            toast.success(`"${filename}" downloaded successfully`);
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    return (
        <>
            {resourceData?.length > 0 ? (
                resourceData?.map((item) => {
                    return (
                        <div className="d-flex w-100 resource-card py-4" key={item?.id}>
                            <IoDocumentTextOutline className="recource-icon  " />
                            <div className="resources-name">{item?.name}</div>
                            <button className="btn resource-btn-download" onClick={() => handleDownload(item)}>
                                <BsCloudArrowDown className="recource-icon" />
                            </button>
                        </div>
                    );
                })
            ) : (
                <>
                    <div className="w-100 mt-3 rounded-4 text-center">
                        <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                        <h5>No Resources Added</h5>
                        <p>No resources available at the moment.</p>
                    </div>
                </>
            )}
        </>
    );
};

export default ResourceCard;
