import React, { useEffect, useState } from "react";
import UnlockedSpecialEvents from "./components/UnlockedSpecialEvents";
import LockedSpecialEvents from "./components/LockedSpecialEvents";
import "./SpecialEvents.css";
import { getSpecialEventService } from "../../services/specialEventsService/SpecialEventsService";
import Loader from "../../components/common/Loader";
import notFound from "../../assets/not-found/not_found.png";
import convertUtcToSelectedTimeZone from "../../utils/common-functions/ConvertUtcToTimezone";
import convertUtcToPst8pdt from "../../utils/common-functions/ConvertUtcToPst8pdt";
import moment from "moment";

const SpecialEvent = () => {
    const [specialEvent, setSpecialEvent] = useState([]);
    const [upcomingSpecialEvent, setUpcomingSpecialEvent] = useState([]);
    const [currentSpecialEvent, setCurrentSpecialEvent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAllSpecialEvents = async () => {
        setIsLoading(true);

        await getSpecialEventService.getSpecialEvents().then((res) => {
            setSpecialEvent(res?.data?.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        getAllSpecialEvents();
    }, []);

    useEffect(() => {
        const currentDate = new Date();

        // const pst8pdtCurrentTime = convertUtcToPst8pdt(currentDate);
        const pst8pdtCurrentTime = currentDate.toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
            hour12: true,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });

        // Filter events where start_date is greater than the current time in PST8PDT
        const upcomingEvents = specialEvent.filter((event) => {
            const eventStartDate = convertUtcToPst8pdt(event.start_date);
            return eventStartDate > new Date(pst8pdtCurrentTime);
        });

        const currentEvents = specialEvent.filter((event) => {
            const eventStartDate = convertUtcToPst8pdt(event.start_date);
            return eventStartDate <= new Date(pst8pdtCurrentTime);
        });

        setCurrentSpecialEvent(currentEvents);
        setUpcomingSpecialEvent(upcomingEvents);
    }, [specialEvent]);

    return (
        <>
            <h3 className="d-lg-block d-none">Live Events</h3>
            <div className="special-event-card-layout mt-4">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {specialEvent?.length > 0 ? (
                            <>
                                <UnlockedSpecialEvents events={currentSpecialEvent} />
                                <LockedSpecialEvents events={upcomingSpecialEvent} />
                            </>
                        ) : (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "400px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h4>No Live Events Added</h4>
                                <p className="fs-6">No Live Events available at the moment.</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default SpecialEvent;
