/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import CatapultLogo from "../../../assets/catapult_mdl.png";
import ModalClose from "../../../assets/modal_close.png";
import PlayVideo from "../../../assets/Play_Circle.png";
import Frame from "../../../assets/frame.png";
import CloudUpload from "../../../assets/Cloud_Upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles/add-project.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ProjectsService } from "../../../services/projectsService/ProjectsService";
import axios from "axios";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { toast } from "react-toastify";
import FileUploadedLoader from "../../../components/common/FileUploadedLoader";
import { MdDelete } from "react-icons/md";

const EditMoreProjects = ({ onClose, mediaType, selectedProjectId, projectIdData, allEditProjectsIdData }) => {
    // Start states
    const [coverImageObjectURL, setCoverImageObjectURL] = useState(projectIdData?.full_cover_image);
    const [contentImageObjectURLs, setContentImageObjectURLs] = useState(projectIdData?.project_medias?.map((media) => media.full_file_path));
    const [isLoading, setIsLoading] = useState(false);
    const [isCoverLoading, setIsCoverLoading] = useState(false);
    const [fileNames, setFileNames] = useState(projectIdData?.project_medias?.map((media) => media.file_path));
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    const [defaultContentImages1, setDefaultContentImages1] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [isDisable, setIsDisable] = useState(false);
    const [quillContent, setQuillContent] = useState(projectIdData?.description);
    const [deletedFilePath, setDeletedFilePath] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    // End  states

    const validationSchema = Yup.object().shape({
        projectTitle: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .required("Project Title is required"),
        projectDescription: Yup.string().required("Project Description is required"),
        coverImage: Yup.mixed().required("Cover Image is required"),
    });

    const handleClose = () => {
        onClose();
    };

    const formik = useFormik({
        initialValues: {
            projectTitle: projectIdData?.title,
            projectDescription: quillContent,
            coverImage: projectIdData?.cover_image,
            contentImages: projectIdData?.project_medias?.map((media) => media.file_path),
            newFiles: [],
            deleted_path_id: [],
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsDisable(false);

            setIsLoading(true);

            deletedFilePath.map((file_path) => {
                ProjectsService.deleteMediaFile({ file_path: file_path }).then();
            });

            const data = {
                title: values.projectTitle,
                description: quillContent,
                cover_image: values.coverImage,
                file_path: values.newFiles,
                _method: "PUT",
            };

            if (isFileDeleted) {
                data.deleted_path_id = values.deleted_path_id;
            }

            await ProjectsService.updatedProjectData(selectedProjectId, data)
                .then((res) => {
                    allEditProjectsIdData();
                    toast.success("Your project has been updated successfully");
                    handleClose();
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });

            formik.resetForm();
            onClose();
        },
    });

    const validateFileSize = (file) => {
        let imageMaxSizeInBytes;
        let videoMaxSixeInBytes;

        if (file.type.startsWith("image")) {
            imageMaxSizeInBytes = 10 * 1023 * 1023;
        } else if (file.type.startsWith("video")) {
            videoMaxSixeInBytes = 25 * 1024 * 1024;
        } else {
            toast.error("Please select a valid file format.");
            return false;
        }

        if (file.size > imageMaxSizeInBytes) {
            toast.error(`Image size must be less than or equal to ${imageMaxSizeInBytes / (1023 * 1023)}MB`);
            return false;
        } else if (file.size > videoMaxSixeInBytes) {
            toast.error(`Video size must be less than or equal to ${videoMaxSixeInBytes / (1024 * 1024)}MB`);
            return false;
        }

        return true;
    };

    const getPresignUrl = async (file_name) => {
        setIsCoverLoading(true);
        setHasChanges(false);
        try {
            const response = await ProjectsService.getPresignUrl(file_name.name);

            if (response?.data?.status === true) {
                await axios.put(response?.data?.data?.url, file_name, {
                    headers: {
                        "Content-Type": file_name.type,
                        "x-amz-acl": "private",
                    },
                });
                formik.setFieldValue("coverImage", response?.data?.data?.file_name);
                setHasChanges(true);
            }
            setIsCoverLoading(false);
        } catch (error) {
            console.error("Error getting pre-signed URL:", error);
            return null;
        }
    };

    // Function to get pre-signed URLs for content images
    const getContentImagePresignUrls = async (files) => {
        setIsLoading(true);
        setHasChanges(false);

        try {
            for (const file of files) {
                const response = await ProjectsService.getPresignUrl(file.name);

                if (response?.data?.status === true) {
                    await axios.put(response?.data?.data?.url, file, {
                        headers: {
                            "Content-Type": file.type,
                            "x-amz-acl": "private",
                        },
                    });

                    const newFilePath = response?.data?.data?.file_name;
                    newFiles.push(newFilePath);
                    setHasChanges(true);
                }
            }
            formik.setFieldValue("newFiles", newFiles);
            setIsLoading(false);
        } catch (error) {
            console.error("Error getting pre-signed URLs for content images:", error);
        }
    };

    const checkContentType = async (url) => {
        return fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.headers.get("content-type");
                } else {
                    throw new Error("Failed to fetch");
                }
            })
            .then((contentType) => {
                if (contentType.startsWith("image")) {
                    return "image";
                } else if (contentType.startsWith("video")) {
                    return "video";
                } else {
                    return "unknown";
                }
            })
            .catch((error) => {
                console.error(error);
                return "error";
            });
    };

    /// Function to handle content image selection
    const handleContentImageChange = async (event) => {
        const files = Array.from(event.currentTarget.files);

        if (files?.length > 0) {
            let isValid = true;

            for (const file of files) {
                if (!validateFileSize(file)) {
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                if (contentImageObjectURLs.length + files.length > 15) {
                    toast.error("You cannot upload more than 15 images or videos.");
                    event.currentTarget.value = null;
                    return;
                }

                await getContentImagePresignUrls(files);
                const objectURLs = await Promise.all(
                    files?.map(async (file, index) => {
                        const url = URL.createObjectURL(file);

                        // Use checkContentType to determine content type
                        const contentType = await checkContentType(url);

                        mediaType?.push(contentType);

                        const newFilePath = newFiles[newFiles.length - 1];

                        setDefaultContentImages1([...contentImageObjectURLs, { url, contentType, file_path: newFilePath }]);

                        return { url, contentType, file_path: newFilePath };
                    })
                );

                setDefaultContentImages1([...defaultContentImages1, ...objectURLs]);
                formik.setFieldValue("contentImages", fileNames);
            } else {
                event.currentTarget.value = null;
            }
        }
    };

    useEffect(() => {
        if (projectIdData?.project_medias) {
            const defaultImages = projectIdData.project_medias.map((item, index) => ({
                url: item.full_file_path,
                contentType: item.media_type,
                file_path: item.file_path,
                id: item.id,
            }));

            setDefaultContentImages1(defaultImages);
        }
    }, []);

    const handleRemoveCover = () => {
        formik.setFieldValue("coverImage", null);
        setCoverImageObjectURL(null);
    };

    const handleRemove = (file_path, file_id) => {
        if (file_id) {
            setIsFileDeleted(true);
            setDefaultContentImages1((images) => images.filter((img) => img.file_path !== file_path));
            fileNames.splice(
                fileNames.findIndex((item) => item === file_path),
                1
            );
            deletedFilePath.push(file_path);
            const updatedDeletedPathIds = [...formik.values.deleted_path_id, file_id];
            formik.setFieldValue("deleted_path_id", updatedDeletedPathIds);
        } else {
            setIsFileDeleted(false);

            newFiles.splice(
                fileNames.findIndex((item) => item === file_path),
                1
            );
            setDefaultContentImages1((prevContentImageObjectURLs) =>
                prevContentImageObjectURLs.filter((item) => {
                    return item.file_path !== file_path;
                })
            );
        }
        setIsDisable(true);
    };

    const renderImageSlider = () => {
        const splideOptions = {
            rewind: true,
        };

        const defaultContentImages = defaultContentImages1?.map((item, index) => {
            return (
                <SplideSlide key={item?.id}>
                    <div className="image-container">
                        {item.contentType === "image" ? (
                            <>
                                {isLoading ? (
                                    <FileUploadedLoader />
                                ) : (
                                    <>
                                        <figure className="image-display m-0">
                                            <img src={item.url} crossOrigin="anonymous" className="add-content-images" alt={`Selected Image`} />
                                        </figure>
                                        <div onClick={() => handleRemove(item.file_path, item?.id)}>
                                            <MdDelete className="delete-icon" />
                                        </div>
                                    </>
                                )}
                            </>
                        ) : item.contentType === "video" ? (
                            <>
                                {isLoading ? (
                                    <FileUploadedLoader />
                                ) : (
                                    <>
                                        <figure className="image-display m-0">
                                            <video controls crossOrigin="anonymous" className="add-content-videos">
                                                <source src={item.url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </figure>
                                        <div onClick={() => handleRemove(item.file_path, item?.id, item?.full_file_path)}>
                                            <MdDelete className="delete-icon" />
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </SplideSlide>
            );
        });

        return (
            <div className={`add-projects-splide ${defaultContentImages?.length > 1 ? "" : "hide-arrow"}`}>
                <Splide options={splideOptions} className="add-projects-splide">
                    {defaultContentImages}
                </Splide>
            </div>
        );
    };

    const handleDescriptionChange = (value) => {
        if (quillContent !== value) {
            setQuillContent(value);
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    };

    useEffect(() => {
        setIsDisable(false);
    }, []);

    useEffect(() => {
        const hasNewFilesChanged = JSON.stringify(newFiles) !== JSON.stringify(formik.values.contentImages);
        const hasFileNamesChanged = JSON.stringify(fileNames) !== JSON.stringify(formik.values.contentImages);

        setHasChanges(hasNewFilesChanged || hasFileNamesChanged);
    }, [newFiles, fileNames, formik.values.contentImages]);

    return (
        <Modal show="true" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-90w" className="add-project-popup ">
            <Modal.Header>
                <img src={CatapultLogo}></img>
                <div className="modal-close" onClick={() => handleClose()}>
                    <img src={ModalClose}></img>
                </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "-webkit-fill-available", overflowY: "auto" }}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row w-100">
                        <div className="box col-sm-12 col-lg-12 col-md-12">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!isDisable || !hasChanges || (fileNames.length === 0 && newFiles.length === 0) || coverImageObjectURL === null}
                                className="ml-30"
                            >
                                Update
                            </Button>
                        </div>
                        <div className="col-md-7 col-sm-12 col-lg-7 mt-4 pr-15">
                            <div>
                                <div className="box-2">
                                    <div className="question">
                                        Impact Title <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2">
                                        <input
                                            type="text"
                                            name="projectTitle"
                                            placeholder="Write your project title here"
                                            onChange={(event) => {
                                                formik.handleChange(event);
                                                setIsDisable(true);
                                            }}
                                            onBlur={(event) => {
                                                formik.handleBlur(event);
                                            }}
                                            value={formik.values.projectTitle}
                                            autoComplete="off"
                                            maxLength={50}
                                        />

                                        {formik.touched.projectTitle && formik.errors.projectTitle && <div className="error">{formik.errors.projectTitle}</div>}
                                    </div>
                                </div>
                                <div className="box-2 mt-4">
                                    <div className="question">
                                        Add Content <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful box-3">
                                        {isLoading ? (
                                            <FileUploadedLoader />
                                        ) : (
                                            <div className="d-flex flex-column align-items-center">
                                                {defaultContentImages1?.length > 0 ? (
                                                    renderImageSlider()
                                                ) : (
                                                    <>
                                                        <img src={CloudUpload} alt="Upload Icon" width={100} height={100} />
                                                        <div className="text-center">
                                                            <div className="text-1 mt-1 mt-lg-3 mt-md-3 text-center">Select upto 15 Images/Videos</div>
                                                            <div className="text-2">Click 'Upload Image' or 'Upload Video' to add media</div>
                                                            <div className="text-2">Images: Max 10MB, Extensions: jpg, jpeg, png</div>
                                                            <div className="text-2">Videos: Max 25MB, Extensions: mp4, mpeg4, webm</div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="btn-container">
                                        <label htmlFor="imageContent" className="file-uploaded">
                                            <div type="button" className={`file-uploaded-btn ${isLoading ? "disabled" : ""}`}>
                                                <img src={Frame} className="mr-5" alt="Image" />
                                                Upload Image
                                            </div>
                                            <input
                                                key={defaultContentImages1}
                                                type="file"
                                                id="imageContent"
                                                accept=".jpg, .png, .jpeg"
                                                style={{ display: "none" }}
                                                multiple
                                                onChange={(event) => {
                                                    handleContentImageChange(event);
                                                    setIsDisable(true);
                                                }}
                                                disabled={isLoading}
                                            />
                                        </label>
                                        <label htmlFor="videoContent" className="file-uploaded">
                                            <div type="button" className={`file-uploaded-btn ${isLoading ? "disabled" : ""}`}>
                                                <img src={PlayVideo} className="mr-5" alt="Video" />
                                                Upload Video
                                            </div>
                                            <input
                                                key={defaultContentImages1}
                                                type="file"
                                                id="videoContent"
                                                accept=".mp4, .mpeg4, .webm"
                                                style={{ display: "none" }}
                                                multiple
                                                onChange={(event) => {
                                                    handleContentImageChange(event);
                                                    setIsDisable(true);
                                                }}
                                                disabled={isLoading}
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className="box-2 mt-4">
                                    <div className="question">
                                        Add Impact Description <span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2">
                                        <ReactQuill
                                            theme="snow"
                                            value={quillContent}
                                            onChange={handleDescriptionChange}
                                            onBlur={(event) => {
                                                setIsDisable(true);
                                                formik.handleBlur("projectDescription", true);
                                            }}
                                        />

                                        {formik.touched.projectDescription && formik.errors.projectDescription && <div className="error">{formik.errors.projectDescription}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 col-lg-5">
                            <div>
                                <div className="box-2 mt-4">
                                    <div className="question">
                                        Cover Image<span className="question-asterisk">*</span>
                                    </div>
                                    <div className="mt-4 w-ful mb-2 box-3" id="fileUploadDiv">
                                        <div>
                                            {coverImageObjectURL ? (
                                                <div className="image-container">
                                                    {isCoverLoading ? (
                                                        <FileUploadedLoader />
                                                    ) : (
                                                        <>
                                                            <figure className="image-display m-0">
                                                                <img src={coverImageObjectURL} crossOrigin="anonymous" className="add-content-images" alt={`Selected Image`} />
                                                            </figure>
                                                            <div onClick={handleRemoveCover}>
                                                                <MdDelete className="delete-icon" />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <label htmlFor="fileInput" className="file-uploaded">
                                                    <img crossOrigin="anonymous" src={CloudUpload} alt="Upload Icon" />
                                                    <div className="text-1 mt-3 text-center">Select image here</div>
                                                    <div className="text-2">Images must be under 10 MB & 25 MB</div>
                                                    <div className="text-2">Image Formats: jpg, jpeg, png</div>
                                                </label>
                                            )}
                                        </div>

                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".jpg, .png, .jpeg"
                                            style={{ display: "none" }}
                                            onChange={async (event) => {
                                                const file = event.currentTarget.files[0];

                                                if (file) {
                                                    if (!validateFileSize(file)) {
                                                        event.currentTarget.value = null;
                                                    } else {
                                                        try {
                                                            const presignUrl = getPresignUrl(file);

                                                            if (presignUrl) {
                                                                const objectURL = URL.createObjectURL(file);
                                                                setCoverImageObjectURL(objectURL);
                                                            } else {
                                                                console.error("Failed to fetch pre-signed URL.");
                                                            }
                                                        } catch (error) {
                                                            console.error("Error creating Object URL or fetching pre-signed URL:", error);
                                                        }
                                                    }
                                                }
                                                setIsDisable(true);
                                            }}
                                        />

                                        {formik.touched.coverImage && formik.errors.coverImage && <div className="error">{formik.errors.coverImage}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditMoreProjects;
