import React, { useEffect, useState } from "react";
import "./sidebarCounter.css";
import { useSelector } from "react-redux";
import convertUtcToSelectedTimeZone from "../../utils/common-functions/ConvertUtcToTimezone";

const SidebarCounter = () => {
    const [targetTime, setTargetTime] = useState(new Date());
    const [remainingTime, setRemainingTime] = useState(0);
    const upcomingKeynoteDate = useSelector((state) => state?.auth?.keynoteDate);

    useEffect(() => {
        setTargetTime(upcomingKeynoteDate ? convertUtcToSelectedTimeZone(upcomingKeynoteDate) : new Date());
    }, [upcomingKeynoteDate]);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const difference = targetTime - now;

            if (difference <= 0) {
                clearInterval(interval);
                setRemainingTime(0);
            } else {
                setRemainingTime(difference);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetTime]);

    const Countdown = ({ hours, minutes, remainingSeconds }) => {
        return (
            <div className="d-flex justify-content-center align-items-center  gap-3 mt-4">
                <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                    <div className="d-flex justify-content-center align-items-center gap-1">
                        {hours?.split("").map((digit, index) => {
                            return (
                                <span key={index} className="upcoming-session-digitbox-dashboard">
                                    {digit}
                                </span>
                            );
                        })}
                        <div className="time-separator-dashboard">:</div>
                    </div>
                    <div className="time-span">Hours</div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                    <div className="d-flex justify-content-center align-items-center gap-1">
                        {minutes?.split("").map((digit, index) => {
                            return (
                                <span key={index} className="upcoming-session-digitbox-dashboard ">
                                    {digit}
                                </span>
                            );
                        })}
                        <div className="time-separator-dashboard">:</div>
                    </div>
                    <div className="time-span">Minutes</div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                    <div className="d-flex justify-content-center align-items-center gap-1">
                        {remainingSeconds?.split("").map((digit, index) => {
                            return (
                                <span key={index} className="upcoming-session-digitbox-dashboard">
                                    {digit}
                                </span>
                            );
                        })}
                    </div>
                    <div className="time-span">Seconds</div>
                </div>
            </div>
        );
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = String(Math.floor(milliseconds / 1000)).padStart(2, "0");
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
        const seconds = String(totalSeconds % 60).padStart(2, "0");

        return <Countdown hours={hours} minutes={minutes} remainingSeconds={seconds} />;
    };
    return (
        <>
            {remainingTime > 0 && (
                <div className="card-layout mt-4">
                    <div className="fw-bold">Next Keynote will start in</div>
                    <div>{formatTime(remainingTime)}</div>
                </div>
            )}
        </>
    );
};

export default SidebarCounter;
