import React, { useEffect, useState } from "react";

import MileStoneCard from "./MileStoneCard";
import "./MileStones.css";
import { useTranslation } from "react-i18next";
import { DashboardService } from "../../services/dashboardService/dashboardService";
import notFound from "../../assets/not-found/not_found.png";

const MileStones = () => {
    const { t } = useTranslation();
    const [mileStones, setMileStones] = useState([]);

    const getMilesStones = () => {
        DashboardService.getMilestone().then((res) => {
            setMileStones(res?.data?.data);
        });
    };
    useEffect(() => {
        getMilesStones();
    }, []);

    return (
        <>
            <div className="card-layout mt-4">
                <div className="d-flex flex-column justify-content-lg-start align-items-lg-start">
                    <div className="w-100 card-layout-header d-flex justify-content-between">
                        <h5>{t("DASHBOARD.TITLE.MILESTONES")}</h5>
                        {/* <div className="mile-stone-viewall">{t("COMMON.VIEW.ALL")}</div> */}
                    </div>
                    {mileStones.length > 0 ? (
                        <div className="mile-stone-body gap-3 mt-4">
                            <div className="w-100">
                                <div className=" d-flex flex-column gap-3">
                                    {mileStones.map((milestone, index) => {
                                        return <MileStoneCard data={milestone} key={index} />;
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="w-100 mt-3 rounded-4 text-center">
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                                <h5>No Milestones Added</h5>
                                <p>No Milestones available at the moment.</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MileStones;
