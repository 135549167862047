import React from "react";
import "../../auth.css";
import logo from "../../../../assets/login/login_logo.png";
import { Link } from "react-router-dom";
import ForgotContentForm from "./ForgotContentForm";
import LanguageContentSection from "../../common/LanguageContentSection";
import { useTranslation } from "react-i18next";

const ForgotContentSection = () => {
  // States start
  const { t } = useTranslation();
  //states end

  return (
    <>
      {/* Forgot content section start */}
      <div className="login-content-section">
        {/* language selection start */}
        <div className="login-language-section">
          <LanguageContentSection />
        </div>
        {/* language selection end */}

        {/* Forgot content form start */}
        <div className="login-content-form">
          <div className="login-catapult-logo">
            <img crossOrigin="anonymous" src={logo} alt="Website Logo" className="logo-image" />
          </div>
          <div className="login-form">
            <ForgotContentForm />
          </div>
        </div>
        {/* Forgot content form end */}

        {/* Forgot content footer start */}
        <div className="login-footer">
          <Link to="/">{t("COMMON.LOGIN.NOW")}</Link>
        </div>
        {/* Forgot content footer end */}
      </div>
      {/* Forgot content section end */}
    </>
  );
};

export default ForgotContentSection;
