import React from "react";
import "./loader.css";
import { Oval } from "react-loader-spinner";

const Loader = () => {
    return (
        <>
            <div className="loader-container">
                {/* <ColorRing
          visible={true}
          height="500"
          width="80"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={[
            "rgb(255, 210, 0)",
            "#515b7d",
            "rgba(78, 128, 245, 1)",
            "#8b0032",
            "rgba(76, 202, 186, 1)",
          ]}
        /> */}
                <Oval height={50} width={50} color="white" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel="oval-loading" secondaryColor="white" strokeWidth={5} strokeWidthSecondary={5} />
            </div>
        </>
    );
};

export default Loader;
