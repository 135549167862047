export const ROUTES = {
    dashboard: "dashboard",
    meetingPage: "dashboard/:type/:id",
};

export const MEETING_TYPES = {
    session: "session",
    previousSession: "previous_session",
    successCoachMeeting: "masterclass-discussion",
    speackerMeeting: "coaching-call",
    specialEvent: "live-event",
    preRecordedSessions: "pre-recorded-sessions",
};
