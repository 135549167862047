import React from "react";
import { Outlet } from "react-router-dom";
import "./outerLayout.css";
import backImage from "../../assets/login/back-ground-image.jpeg";
const OuterLayout = () => {
    return (
        <section className="login-section">
            <div className="login-section-row">
                <div className="container-fluid login-container">
                    <div className="row" style={{ background: "#15181B" }}>
                        <div className="col-md-6 d-none d-lg-flex d-md-flex d-sm-flex ">
                            <img src={backImage} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-md-6">
                            <div className="login-banner">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OuterLayout;
