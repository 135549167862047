import React from "react";
import moment from "moment";
import "moment-timezone";
import Select from "react-select";

const allZones = moment.tz.names();
allZones.unshift("clear");

export default function TimezoneSelect({ defaultTZ = moment.tz.guess(), timezone, setTimezone }) {
    const options = allZones.map((c, idx) => ({
        value: c !== "clear" ? c : "",
        label: c,
    }));

    const onChange = (selectedOption) => {
        setTimezone(selectedOption.value ? selectedOption.value : defaultTZ);
    };

    return (
        <div>
            <Select options={options} value={options.find((option) => option.value === timezone)} onChange={onChange} />
        </div>
    );
}
