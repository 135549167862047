import apiClient from "../../utils/apiClient";

const GET_SPECIAL_EVENTS = "/get-special-events";

const getSpecialEvents = () => {
    return apiClient.get(GET_SPECIAL_EVENTS);
};

export const getSpecialEventService = {
    getSpecialEvents,
};
