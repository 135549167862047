import moment from "moment";
import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "../Style/Schedular.css";
import { schedularService } from "../../../services/schedularCalendarService/SchedularCalendarService";
import { toast } from "react-toastify";
import TimezoneSelect from "../Components/TimeZoneSelector";

const defaultTZ = "PST8PDT";

const AddEventsPopup = ({ add, onClose, selectedSlot, selectedDate, getAllSpeakerAvailabilityData }) => {
    // start states
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [timezone, setTimezone] = useState(defaultTZ);
    // end states

    // validation Schema
    const validationSchema = Yup.object().shape({
        start_time: Yup.string()
            .required("Start Time is required")
            .test("is-after-current-time", "Start Time must be after the current time", function (value) {
                const currentTime = moment();
                const selectedDate = moment(formik.values.date);
                const selectedDateTime = moment(selectedDate).set({
                    hour: moment(value, "HH:mm").hours(),
                    minute: moment(value, "HH:mm").minutes(),
                    second: 0,
                });
                return selectedDateTime.isAfter(currentTime);
            })
            .test("is-greater-than-end-time", "Start Time must be before End Time", function (value) {
                return moment(value, "HH:mm").isBefore(moment(this.parent.end_time, "HH:mm"));
            }),
        end_time: Yup.string()
            .required("End Time is required")
            .test("is-greater-than-start-time", "End Time must be greater than Start Time", function (value) {
                const startTime = moment(this.parent.start_time, "HH:mm");
                return moment(value, "HH:mm").isAfter(startTime);
            })
            .test("is-minimum-difference", "End Time must have a minimum difference of 30 minutes", function (value) {
                const startTime = moment(this.parent.start_time, "HH:mm");
                const endTime = moment(value, "HH:mm");
                const minDifference = moment.duration(30, "minutes");
                return endTime.diff(startTime) >= minDifference;
            }),
    });

    // fuction of close modal
    const handleClose = () => {
        onClose();
    };

    // function of submit form and also called addAvailableSchedule api
    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const newEvent = {
                date: formik.values.date,
                start_time: formik.values.start_time,
                end_time: formik.values.end_time,
                timezone: timezone,
            };

            await schedularService.addAvailableSchedule(newEvent);

            getAllSpeakerAvailabilityData();
            toast.success("Your availability has been added successfully.");

            handleClose();
        } catch (error) {
            console.error("API Error:", error);
        }
        setIsSubmitting(false);
    };

    // initial values
    const initialValues = {
        date: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "",
        start_time: selectedSlot ? moment(selectedSlot?.start).format("HH:mm") : "",
        end_time: selectedSlot ? moment(selectedSlot?.end).format("HH:mm") : "",
    };

    // formik
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    // Function to disable past time hours, minutes, and seconds
    const disabledTime = () => {
        const currentTime = moment();
        const currentHour = currentTime.hour();
        const currentMinute = currentTime.minute();
        const currentSecond = currentTime.second();

        return {
            disabledHours: () => Array.from({ length: currentHour }),
            disabledMinutes: (hour) => (hour === currentHour ? Array.from({ length: currentMinute }) : []),
            disabledSeconds: (hour, minute) => (hour === currentHour && minute === currentMinute ? Array.from({ length: currentSecond }) : []),
        };
    };

    return (
        // modal start
        <Modal show={add} onHide={handleClose} className="add-time-modal">
            {/* start modal header  */}
            <Modal.Header>
                <h5 className="m-0">
                    Add Availability For&nbsp;
                    {moment(formik.values.date).format("MMMM Do, YYYY")}
                </h5>
                <AiOutlineClose fontSize={24} style={{ color: "black" }} onClick={handleClose} />
            </Modal.Header>
            {/* end modal header  */}
            {/* start modal body  */}
            <Modal.Body style={{ maxHeight: "500px", overflowY: "scroll" }}>
                <Form>
                    <div className="add-event-popup d-flex flex-wrap">
                        <Form.Group className="add-timeZone">
                            <div className="justify-content-start d-flex flex-column gap-3">
                                <span className="fs-6 fw-bold">Select Time Zone :</span>
                                <TimezoneSelect defaultTZ={defaultTZ} setTimezone={setTimezone} timezone={timezone} />
                            </div>
                        </Form.Group>

                        <div className="d-flex gap-2 gap-lg-3 gap-md-3">
                            <Form.Group className="add-start-time">
                                <Form.Label className="fw-bold">Start Time :</Form.Label>
                                <TimePicker
                                    showSecond={false}
                                    format="hh:mm A"
                                    placeholder="Start Time"
                                    onChange={(time) => {
                                        formik.setFieldValue("start_time", time && time.format("HH:mm"));
                                    }}
                                    value={formik.values.start_time ? moment(formik.values.start_time, "HH:mm") : null}
                                    onBlur={formik.handleBlur("start_time")}
                                    minuteStep={15}
                                    use12Hours={true}
                                    {...disabledTime()}
                                />
                                {formik.errors.start_time && formik.touched.start_time && (
                                    <div className="error" style={{ color: "red", fontSize: "12px" }}>
                                        {formik.errors.start_time}
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className="add-end-time">
                                <Form.Label className="fw-bold">End Time :</Form.Label>
                                <TimePicker
                                    showSecond={false}
                                    placeholder="End Time"
                                    format="hh:mm A"
                                    onChange={(time) => {
                                        formik.setFieldValue("end_time", time && time.format("HH:mm"));
                                    }}
                                    use12Hours={true}
                                    value={formik.values.end_time ? moment(formik.values.end_time, "HH:mm") : null}
                                    onBlur={formik.handleBlur("end_time")}
                                    minuteStep={15}
                                    {...disabledTime()}
                                />
                                {formik.errors.end_time && formik.touched.end_time && (
                                    <div className="error" style={{ color: "red", fontSize: "12px" }}>
                                        {formik.errors.end_time}
                                    </div>
                                )}
                            </Form.Group>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            {/* end modal body  */}
            {/* start modal footer  */}
            <Modal.Footer>
                <Button className="submit-btn" type="submit" onClick={formik.handleSubmit} disabled={isSubmitting}>
                    Submit
                </Button>
            </Modal.Footer>
            {/* end modal footer  */}
        </Modal>
        // modal end
    );
};

export default AddEventsPopup;
