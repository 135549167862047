import React from "react";
import "../community.css";
import DiscussionAccordion from "./discussion-accordion";

const Discussion = ({ isFirst, discussion, handleAddPostData, getGeneralDiscussion }) => {
    const isLink = (text) => {
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(text.trim());
    };

    if (!discussion || !discussion.general_questions) {
        return null;
    }
    return (
        <>
            <div className="heading">{discussion.general_questions.question}</div>
            <div className="heading-2 mt-2">
                {discussion.general_questions.description.split(/\s+/).map((item, index, array) => (
                    <React.Fragment key={index}>
                        {isLink(item) ? (
                            <>
                                <a href={item} target="_blank" rel="noopener noreferrer">
                                    {item}
                                </a>
                                {index < array.length - 1 && " "}
                            </>
                        ) : (
                            <>
                                <span>{item}</span>
                                {index < array.length - 1 && " "}
                            </>
                        )}
                    </React.Fragment>
                ))}
            </div>
            <DiscussionAccordion question={discussion.general_questions} handleAddPostData={handleAddPostData} getGeneralDiscussion={getGeneralDiscussion} />
        </>
    );
};
export default Discussion;
