import apiClient from "../../utils/apiClient";

const GET_NOTIFICATION = "/get-notification";
const READ_NOTIFICATION = "/read-notification";
const POST_NOTIFICATION = "/open-notification";

const getNotification = () => {
    const url = `${GET_NOTIFICATION}`;

    return apiClient.get(url);
};

const readNotification = (ids) => {
    const url = `${READ_NOTIFICATION}`;
    return apiClient.post(url, ids);
};

const postNotification = () => {
    const url = `${POST_NOTIFICATION}`;
    return apiClient.post(url);
};
export const notificationService = {
    getNotification,
    readNotification,
    postNotification,
};
