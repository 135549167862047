import React, { useState, useEffect } from "react";
import "./miniCourses.css";
import MiniCoursesQuestions from "./components/MiniCoursesQuestions";
import MiniCoursesDiscussion from "./components/MiniCoursesDiscussion";
import { miniBookService } from "../../services/miniBookService/miniBookService";
import Loader from "../../components/common/Loader";
import notFound from "../../assets/not-found/not_found.png";
import Select from "react-select";

const MiniCourses = () => {
    const [playBooks, setPlayBooks] = useState([]);
    const [flag, setFlag] = useState(false);
    const [keyNotes, setKeyNotes] = useState([]);
    const [selectedKeyNote, setSelectedKeyNote] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setIsLoading(true);
        getPlayBooks();
    }, []);

    const getPlayBooks = () => {
        setIsLoading(true);
        miniBookService.getPlaybookCourses(selectedKeyNote).then((res) => {
            setPlayBooks(res?.data?.data);
            setFlag(res?.data?.isMeetingScheduled);
            setIsLoading(false);
        });
    };

    const getKeyNotes = () => {
        setIsLoading(true);

        miniBookService.getKeyNoteList().then((res) => {
            setKeyNotes(res?.data?.data);
            setIsLoading(false);
        });
    };

    const handleKeyNoteChange = (e) => {
        setSelectedKeyNote(e.value);
    };

    useEffect(() => {
        getKeyNotes();
    }, []);

    const handleAddPostData = (id, data) => {
        let tempPlayBook = JSON.parse(JSON.stringify(playBooks));
        if (data) {
            tempPlayBook = tempPlayBook.map((r) => {
                r.play_book_course_questions.map((p) => {
                    if (p.id == id) {
                        p.community_discussion = data.data.data;
                    }
                    return p;
                });

                return r;
            });
            setPlayBooks(tempPlayBook);
        }
    };

    useEffect(() => {
        getPlayBooks();
    }, [selectedKeyNote]);

    return (
        <>
            <h3 className="d-lg-block d-none">Playbook</h3>
            <div className="card-layout mini-course-layout">
                {flag && playBooks?.length > 0 ? (
                    <>
                        <div className="d-flex align-center">
                            <div className="d-flex flex-column flex-md-row flex-lg-row gap-10 align-items-start align-items-lg-center align-items-md-center">
                                <h6 className="m-0">Keynotes</h6>
                                <Select
                                    onChange={(selectedOption) => handleKeyNoteChange(selectedOption)}
                                    options={keyNotes.map((keyNote) => ({
                                        value: keyNote.id,
                                        label: keyNote.name,
                                    }))}
                                    placeholder="Select keynotes "
                                />
                            </div>
                        </div>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {playBooks.map((playBook, key2) => {
                                    return (
                                        <div className={key2 == 0 ? "mt-4" : "mt-2"} key={key2}>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <span className="key-note-title">{playBook.play_book_course.name}</span>
                                                </div>
                                            </div>
                                            {playBook.play_book_course_questions.map((playBookQuestion, key) => {
                                                return (
                                                    <div key={key}>
                                                        <MiniCoursesQuestions playBookQuestion={playBookQuestion} key={key} />
                                                        <div className="card-layout questions-layout mt-2 mini-course-discussion">
                                                            <MiniCoursesDiscussion playBookQuestion={playBookQuestion} handleAddPostData={handleAddPostData} getCommunity={getPlayBooks} />
                                                        </div>
                                                        {key2 != playBooks.length - 1 && key == playBook.play_book_course_questions.length - 1 && (
                                                            <>
                                                                <hr className="mt-4"></hr>
                                                            </>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "500px" }}>
                            <img crossOrigin="anonymous" src={notFound} alt="data not found" width={300} />
                            <h4>No Playbook Added</h4>
                            <p className="fs-6">No playbook available at the moment.</p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MiniCourses;
