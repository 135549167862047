import apiClient from "../../utils/apiClient";

const TODO_LIST = "/to-do-lists";

const getTodoList = () => {
  const url = TODO_LIST;
  return apiClient.get(url);
};

const addTodo = (data) => {
  const url = TODO_LIST;
  return apiClient.post(url, data);
};

const updateTodo = (id, data) => {
  const url = `${TODO_LIST}/${id}`;
  return apiClient.post(url, data);
};

const changeTodoStatus = (id, data) => {
  const url = `${TODO_LIST}/change-status/${id}`;
  return apiClient.post(url, data);
};

const deleteTodo = (id) => {
  const url = `${TODO_LIST}/${id}`;
  return apiClient.delete(url);
};

export const todoService = {
  getTodoList,
  addTodo,
  updateTodo,
  changeTodoStatus,
  deleteTodo,
};
