import React from "react";

const MileStoneCard = ({ data }) => {
  return (
    <div className={`${data.completed ? 'completed ' : 'due '}mile-stone-card-layout`}>
      <div className="milestone-header mb-3 ">{data.keynote_name ??data.question_title}</div>
      <div className="milestone-status mb-1">{data.completed == true ? 'Completed': 'Pending'}</div>
    </div>
  );
};

export default MileStoneCard;
