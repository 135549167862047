/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
// import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";
import ShowAllDiscussionPosts from "./ShowAllDiscussionPosts";
import AddPlus from "../../../assets/mini-courses/Add_Plus.svg";
import AddPost from "../modal/add-post";
const MiniCoursesDiscussion = ({ playBookQuestion, handleAddPostData, getCommunity }) => {
    // const [showDiscussion, setShowDiscussion] = useState(true);
    // const toggleDiscussion = () => {
    //   setShowDiscussion(!showDiscussion);
    // };
    const [showAddModal, setShowAddModal] = useState(false);

    const handleAddPost = () => {
        setShowAddModal(true);
    };

    const closeAddPost = (data) => {
        setShowAddModal(false);
        handleReload(data);
    };

    const handleReload = (data) => {
        handleAddPostData(playBookQuestion.id, data);
    };

    return (
        <>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between accr">
                    <h6 className="fw-bold fs-6 w-100">{playBookQuestion.question}</h6>
                    <div className="add-post cursor-pointer w-100" onClick={handleAddPost}>
                        <img crossOrigin="anonymous" src={AddPlus} style={{ marginRight: "5px" }}></img>
                        Reply to Post
                    </div>
                    {/* {showDiscussion ? (
            <div className="discussion-toggle" onClick={toggleDiscussion}>
              <p className="d-xl-block d-none">Hide Discussion</p>
              <PiCaretUpBold className="mb-3 fs-5" />
            </div>
          ) : (
            <div className="discussion-toggle" onClick={toggleDiscussion}>
              <p className="d-xl-block d-none">Show Discussion</p>
              <PiCaretDownBold className="m-0 fs-5" />
            </div>
          )} */}
                </div>
            </div>
            <ShowAllDiscussionPosts communityDiscussion={playBookQuestion.community_discussion} handleAddPostData={handleAddPostData} getCommunity={getCommunity} />

            {showAddModal && <AddPost onClose={closeAddPost} questionId={playBookQuestion.id} />}
        </>
    );
};

export default MiniCoursesDiscussion;
