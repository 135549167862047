import apiClient from "../../utils/apiClient";

const RESET_DATA = "/reset_password";

const addResetData = (data) => {
  const url = `${RESET_DATA}`;
  return apiClient.post(url, data);
};

export const resetService = {
  addResetData,
};
