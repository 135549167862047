import apiClient from "../../utils/apiClient";

const USER_PROFILE_DATA = "/users";
const USER_PROFILE_IMAGE = "/update_profile_image";

const getUserProfileData = (id) => {
  const url = `${USER_PROFILE_DATA}/${id}`;
  return apiClient.get(url);
};

const addUserProfileData = (id, data) => {
  const url = `${USER_PROFILE_DATA}/${id}`;
  return apiClient.post(url, data);
};

const updatedProfileImage = (data) => {
  const url = `${USER_PROFILE_IMAGE}`;
  return apiClient.post(url, data);
};

export const profileSettingService = {
  getUserProfileData,
  addUserProfileData,
  updatedProfileImage,
};
