// import momentTimezone from "moment-timezone";
import moment from "moment";
import UpcomingSessionCountdown from "../UpcomingSessionCountdown/UpcomingSessionCountdown";
import ZoomMeetPage from "./Zoom";
import RecordedVideoSession from "../../pages/dashboard/components/RecordedVideoSession";
import YoutubeSession from "../../pages/dashboard/components/YoutubeSession";
import ZoomImage from "../../assets/Zoom_Communications_Logo.png";
import { useEffect, useMemo, useState } from "react";
import helpers from "../../utils/common-functions/Helper";
import { useSelector } from "react-redux";

const SessionComponent = ({ selectedSession, isPreRecordedSession, isPreviousSession }) => {
    const [meetingStartAndEndConfig, setMeetingStartAndEndConfig] = useState(null);
    const authUser = useSelector((state) => state?.auth?.userData);
    const [clickEvent, setClickEvent] = useState(false);

    useEffect(() => {
        const reCheckTime = () => {
            setMeetingStartAndEndConfig((prevStates) => {
                return {
                    ...prevStates,
                    isStart: moment().tz("UTC").isAfter(moment.utc(selectedSession.start_date)),
                    // isEnd: moment().tz("UTC").isAfter(moment.utc(selectedKeyNote.end_date)),
                };
            });
        };

        reCheckTime();
        const intervalId = setInterval(reCheckTime, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [selectedSession]);

    const isValidMeeting = useMemo(() => {
        try {
            const zoomDetails = selectedSession?.key_note_online_booking?.details;
            if (!zoomDetails) {
                return false;
            }
            const meetingId = zoomDetails?.meetingId?.toString();
            const zoomMeetingPassword = zoomDetails?.zoomMeetingPassword?.toString();

            return !helpers.isEmpty(meetingId) && !helpers.isEmpty(zoomMeetingPassword);
        } catch (error) {
            console.error("Error calculating isValidMeeting:", error);
            return false;
        }
    }, [selectedSession]);

    const isNotEnd = useMemo(() => {
        return isValidMeeting && meetingStartAndEndConfig?.isStart && !moment().tz("UTC").isAfter(moment.utc(selectedSession.end_date));
    }, [isValidMeeting, meetingStartAndEndConfig?.isStart, selectedSession.end_date]);

    if (helpers.isEmpty(meetingStartAndEndConfig)) {
        return null;
    }

    return (
        <>
            {isPreRecordedSession ? (
                <>
                    {(() => {
                        if (selectedSession.video_url) {
                            return <YoutubeSession video={selectedSession.video_url} id={selectedSession?.id} />;
                        } else if (selectedSession.video_path) {
                            return <RecordedVideoSession video={selectedSession.full_video_path} id={selectedSession?.id} />;
                        } else if (selectedSession.key_note_online_booking) {
                            if (isNotEnd) {
                                let meetingConfig = selectedSession.key_note_online_booking.details;
                                return (
                                    <>
                                        <div className={`${clickEvent ? "zoom-card-background" : "zoom-card"}`}>
                                            <div className={`column ${clickEvent ? "d-flex" : "d-none"}`} id="meetingSDKElement"></div>
                                            <ZoomMeetPage
                                                video={{
                                                    meetingId: meetingConfig.meetingId,
                                                    zoomMeetingPassword: meetingConfig.zoomMeetingPassword,
                                                    userEmail: authUser?.email,
                                                    userId: authUser?.id,
                                                    userName: `${authUser?.first_name} ${authUser?.last_name}`,
                                                    zoomAccessToken: meetingConfig.zoomAccessToken,
                                                    role: authUser?.user_type === 4 ? 1 : 0,
                                                }}
                                                setClickEvent={setClickEvent}
                                                clickEvent={clickEvent}
                                            />
                                        </div>
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <div className="zoom-card">
                                            <div className="zoom-cntr">
                                                <img src={ZoomImage} className="zoom-img" alt="Zoom"></img>
                                                <div className="zoom-end-text mt-4">This meeting has been ended</div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        } else {
                            return null;
                        }
                    })()}
                </>
            ) : meetingStartAndEndConfig.isStart || isPreviousSession ? (
                <>
                    {(() => {
                        if (selectedSession.video_url) {
                            return <YoutubeSession video={selectedSession.video_url} id={selectedSession?.id} />;
                        } else if (selectedSession.video_link) {
                            return <RecordedVideoSession video={selectedSession.full_video_link} id={selectedSession?.id} />;
                        } else if (selectedSession.key_note_online_booking) {
                            if (isNotEnd) {
                                let meetingConfig = selectedSession.key_note_online_booking.details;
                                return (
                                    <>
                                        <div className={`${clickEvent ? "zoom-card-background" : "zoom-card"}`}>
                                            <div className={`column ${clickEvent ? "d-flex" : "d-none"}`} id="meetingSDKElement"></div>
                                            <ZoomMeetPage
                                                video={{
                                                    meetingId: meetingConfig.meetingId,
                                                    zoomMeetingPassword: meetingConfig.zoomMeetingPassword,
                                                    userEmail: authUser?.email,
                                                    userId: authUser?.id,
                                                    userName: `${authUser?.first_name} ${authUser?.last_name}`,
                                                    zoomAccessToken: meetingConfig.zoomAccessToken,
                                                    role: authUser?.user_type === 4 ? 1 : 0,
                                                }}
                                                setClickEvent={setClickEvent}
                                                clickEvent={clickEvent}
                                            />
                                        </div>
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <div className="zoom-card">
                                            <div className="zoom-cntr">
                                                <img src={ZoomImage} className="zoom-img" alt="Zoom"></img>
                                                <div className="zoom-end-text mt-4">This meeting has been ended</div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        } else {
                            return null;
                        }
                    })()}
                </>
            ) : (
                <UpcomingSessionCountdown sessionStartTime={selectedSession.start_date} />
            )}
        </>
    );
};

export default SessionComponent;
