import React from "react";
import "./loader.css";
import { ThreeDots } from "react-loader-spinner";

const ImageUploadedLoader = () => {
    return (
        <>
            <div className="file-loader-container">
                <ThreeDots height="50" width="50" radius="9" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
            </div>
        </>
    );
};

export default ImageUploadedLoader;
