import React, { useEffect, useState } from "react";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { BsCameraVideoFill } from "react-icons/bs";
import KJUR from "jsrsasign";
import "./Zoom.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdOpenInFull, MdCloseFullscreen } from "react-icons/md";

function generateSignature(key, secret, meetingNumber, role) {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;
    const oHeader = { alg: "HS256", typ: "JWT" };

    const oPayload = {
        sdkKey: key,
        appKey: key,
        mn: meetingNumber,
        role: role,
        iat: iat,
        exp: exp,
        tokenExp: exp,
    };

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const sdkJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, secret);
    return sdkJWT;
}

const ZoomMeetPage = ({ video, clickEvent, setClickEvent }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isScreenSharing, setIsScreenSharing] = useState("Stop");

    const handleFullScreen = () => {
        if (document.fullscreenEnabled) {
            const meetingSDKElement = document.getElementById("meetingSDKElement");
            if (meetingSDKElement) {
                meetingSDKElement.requestFullscreen();
                setIsFullScreen(true);
            }
        }
    };

    useEffect(() => {
        const meetingSDKElement = document.getElementById("meetingSDKElement");
        if (meetingSDKElement) {
            if (clickEvent) {
                meetingSDKElement.classList.add("small-screen-mode");
            }
        }
    }, [clickEvent]);

    useEffect(() => {
        const meetingSDKElement = document.getElementById("meetingSDKElement");
        if (meetingSDKElement) {
            if (isScreenSharing === "Start") {
                meetingSDKElement.classList.add("sharing-screen-mode");
            } else {
                meetingSDKElement.classList.remove("sharing-screen-mode");
            }
        }
    }, [isScreenSharing]);

    useEffect(() => {
        const meetingSDKElement = document.getElementById("meetingSDKElement");
        if (meetingSDKElement) {
            if (isScreenSharing === "Stop") {
                meetingSDKElement.classList.remove("small-screen-mode");
            }
        }
    }, [isScreenSharing]);

    useEffect(() => {
        if (isFullScreen) {
            const meetingSDKElement = document.getElementById("meetingSDKElement");
            if (meetingSDKElement) {
                meetingSDKElement.classList.add("full-screen-mode");
                meetingSDKElement.classList.remove("small-screen-mode");
            }
        } else {
            const meetingSDKElement = document.getElementById("meetingSDKElement");
            if (meetingSDKElement) {
                meetingSDKElement.classList.remove("full-screen-mode");
                meetingSDKElement.classList.add("small-screen-mode");
            }
        }
    }, [isFullScreen]);

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(document.fullscreenElement !== null);
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, []);

    const handleExitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const getSignature = async () => {
        setClickEvent(true);
        try {
            let role = parseInt(video?.hasOwnProperty("role") ? video.role : 0);

            let signature = generateSignature(process.env.REACT_APP_SDK_KEY, process.env.REACT_APP_SDK_SECRET, parseInt(video.meetingId), role);
            let meetingSDKElement = document.getElementById("meetingSDKElement");

            let meetingSDKChatElement = document.getElementById("meetingSDKChatElement");

            const videoCanvasElement = document.getElementById("zoom-sdk-video-canvas");
            const rootElement225 = document.getElementById("zmwebsdk-makeStyles-root-225");

            const ulElement = document.createElement("ul");
            ulElement.className = "zmwebsdk-makeStyles-avatarList-222";

            if (videoCanvasElement && rootElement225) {
                videoCanvasElement.parentNode.insertBefore(ulElement, rootElement225);
            }
            const client = await ZoomMtgEmbedded.createClient();

            await client.init({
                debug: true,
                webEndpoint: "zoom.us",
                zoomAppRoot: meetingSDKElement,
                language: "en-US",
                disableDraggable: true,
                leaveUrl: "https://projects.wappnet.us/catapult-frontend/dashboard",
                customize: {
                    meeting: {
                        popper: {
                            disableDraggable: true,
                        },
                    },
                    video: {
                        isResizable: false,
                        defaultViewType: "gallery",
                        maximumVideosInGalleryView: 4,
                        popper: {
                            disableDraggable: true,
                        },
                        viewSizes: {
                            default: {
                                width: 600,
                                height: "100%",
                            },
                        },
                    },
                    activeApps: {
                        popper: {
                            disableDraggable: true,
                        },
                    },
                    chat: {
                        popper: {
                            disableDraggable: true,
                            anchorElement: meetingSDKChatElement,
                            placement: "top",
                        },
                    },
                },
            });

            client.on("peer-share-state-change", (status) => {
                setIsScreenSharing(status.action);
            });

            let joinBody = {
                sdkKey: process.env.REACT_APP_SDK_KEY,
                signature: signature,
                meetingNumber: parseInt(video.meetingId),
                password: video.zoomMeetingPassword,
                userName: video?.userName ?? "Meeting",
                userEmail: video?.userEmail,
                customerKey: video?.userId?.toString(),
                disableDrag: true,
            };

            await client.join(joinBody);

            let intervalId = setInterval(async () => {
                try {
                    const meetingInfo = await client.getCurrentMeetingInfo();

                    if (meetingInfo.isInMeeting === false) {
                        navigate("/dashboard");
                        window.location.reload();
                        scrollToTop();
                        clearInterval(intervalId);
                    }
                } catch (error) {}
            }, 5000);
        } catch (error) {
            if (error.type === "INVALID_OPERATION") {
                toast.error("You've already joined this meeting. Please Refresh to rejoin.");
            } else {
                toast.error(error?.reason);
            }
            navigate("/dashboard");
        }
    };

    return clickEvent ? (
        isFullScreen ? (
            <span className="zoom-full-screen-icon" onClick={handleExitFullScreen}>
                <MdCloseFullscreen />
            </span>
        ) : (
            <span className="zoom-full-screen-icon" onClick={handleFullScreen}>
                <MdOpenInFull />
            </span>
        )
    ) : (
        <div className="zoom-meeting-join">
            <p className="text-center mb-3">Welcome to the Zoom Meeting!</p>
            <p className="text-center mb-3">Please click the "Go Live" button to join the meeting.</p>
            <div className="d-flex justify-content-center">
                <button className="go-live-button px-3 py-2" onClick={getSignature}>
                    <BsCameraVideoFill className="fs-5" />
                    &nbsp;Go Live
                </button>
            </div>
        </div>
    );
};

export default ZoomMeetPage;
