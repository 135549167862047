import React from "react";

import Asidebar from "./asidebar";

const LeftSidebar = ({ isLoading }) => {
  return (
    // Left sidebar start
    <aside className="left-sidebar left-sidebar-desktop d-lg-flex d-none">
      <Asidebar isLoading={isLoading} />
    </aside>
    // Left sidebar end
  );
};

export default LeftSidebar;
