import React, { useEffect, useState } from "react";
import "./profileSettings.css";
import InnerLayout from "../../layout/InnerLayout";
import ProfileSettingsForm from "./ProfileSettingsForm";
import ProfileImageUpdated from "./ProfileImageUpdated";
import { profileSettingService } from "../../services/profileSettingService/profileSettingService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/authSlice";
import Loader from "../../components/common/Loader";

const ProfileSettings = () => {
    // states start
    const loginUserData = useSelector((state) => state?.auth?.auth);
    const [getUserData, setGetUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    // states end

    // useEffect for getting all user data
    useEffect(() => {
        getUserAllData();
    }, []);

    const getUserAllData = async () => {
        try {
            const response = await profileSettingService.getUserProfileData(loginUserData?.id);
            const fetchedUserData = response?.data?.data;
            dispatch(setUserData(fetchedUserData));
            setGetUserData(fetchedUserData);
            setIsLoading(false);
        } catch (error) {
            console.error("API error:", error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <h3 className="d-lg-block d-none ">Profile Settings</h3>
            <div className="card-layout mt-4 profile-settings-section">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <ProfileImageUpdated getUserAllData={getUserAllData} getUserData={getUserData} setIsLoading={setIsLoading} />
                        <div className="mt-4">
                            <ProfileSettingsForm getUserAllData={getUserAllData} getUserData={getUserData} setIsLoading={setIsLoading} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ProfileSettings;
