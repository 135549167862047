import apiClient from "../../utils/apiClient";

const GET_SESSION = "/session";

const getSessions = () => {
    return apiClient.get(GET_SESSION);
}

export const getSessionsService = {
    getSessions
}