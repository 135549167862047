/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
// import momentTimezone from "moment-timezone";
// import moment from "moment";
import "./dashboard.css";
import PreviousSessions from "../../components/PreviousSessions/PreviousSessions";
import Loader from "../../components/common/Loader";
import { useTranslation } from "react-i18next";
import { getSessionsService } from "../../services/sessionsService/sessionsService";
import Confetti from "react-confetti";
import CongratulationsModal from "./modals/congratulations-modal";
import { DashboardService } from "../../services/dashboardService/dashboardService";
import MiniCoursesDiscussion from "../playbook-mini-courses/components/MiniCoursesDiscussion";
import { MEETING_TYPES, ROUTES } from "../../constants";
import NoSessionsAvailable from "../../components/common/NoSessionsAvailable";
import SessionComponent from "../../components/Zoom/SessionComponent";
import helpers from "../../utils/common-functions/Helper";
import CommonZoomPlayComponent from "../../components/Zoom/CommonZoomPlayComponent";
import { useSelector } from "react-redux";
import convertUtcToSelectedTimeZone from "../../utils/common-functions/ConvertUtcToTimezone";
import { setUpcomingKeyNoteDate } from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import { MdOutlineRateReview } from "react-icons/md";
import CongratulationsSurveyModal from "./modals/CongratulationsSurveyModal";

const Dashboard = ({ setIsPreviousSession, isPreviousSession }) => {
    const masterClass = localStorage.getItem("masterClass");
    const userType = useSelector((state) => state?.auth?.userData?.user_type);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showCongratulationsModal, setShowCongratulationsModal] = useState(false);
    const [showSurveyModal, setShowSurveyModal] = useState(false);

    const progressBarCount = useSelector((state) => state?.auth?.progressBar);
    const [upcomingKeynote, setUpcomingKeynote] = useState([]);
    const [flag, setFlag] = useState(false);
    const [sessionFlag, setSessionFlag] = useState(false);
    const [isPreRecordedSession, setIsPreRecordedSession] = useState(false);
    const [state, setState] = useState({
        loader: false,
        previousSessions: [],
        PreRecordedSessions: [],
        selectedKeyNote: "",
        selectedPreRecordedSession: "",
        selectedKeyNoteDiscussion: [],
        otherMeetingDetails: null,
    });
    const authUser = useSelector((state) => state?.auth?.userData);
    const params = useParams();
    const location = useLocation();
    const isDashboardPage = location.pathname === "/" + ROUTES.dashboard;
    const isSuccessCoachMeeting = !isDashboardPage && params.type === MEETING_TYPES.successCoachMeeting;
    const isSpeackerMeeting = !isDashboardPage && params.type === MEETING_TYPES.speackerMeeting;
    const isSpecialEvent = !isDashboardPage && params.type === MEETING_TYPES.specialEvent;
    const isSession = !isDashboardPage && params.type === MEETING_TYPES.session;
    const isPrevSessions = !isDashboardPage && params.type === MEETING_TYPES.previousSession;
    const isPreRecordedQuestion = !isDashboardPage && params.type === MEETING_TYPES.preRecordedSessions;

    useEffect(() => {
        if (isDashboardPage) {
            getPrevSessions();
            setIsPreRecordedSession(false);
            setIsPreviousSession(false);
        } else if (isPrevSessions) {
            getPrevSessions();
        } else if (isPreRecordedQuestion) {
            getPrevSessions();
        }
    }, [isDashboardPage]);

    useEffect(() => {
        if (progressBarCount >= 60) {
            setShowCongratulationsModal(true);
            setShowSurveyModal(false);
        }
    }, []);

    useEffect(() => {
        if (progressBarCount < 60) {
            setShowSurveyModal(true);
        } else {
            setShowSurveyModal(false);
        }
    }, [progressBarCount]);

    useEffect(() => {
        if (!(isSuccessCoachMeeting || isSpeackerMeeting || isSpecialEvent)) {
            getDashboardSession(params.id);
        }

        if (isSuccessCoachMeeting || isSpeackerMeeting || isSpecialEvent) {
            let requestType = isSpeackerMeeting ? "poc_speaker_session" : isSuccessCoachMeeting ? "poc_success_coach_session" : "special_events";
            getOtherMeetingDetails(requestType, params.id);
        }
    }, [params, isDashboardPage, isSuccessCoachMeeting, isSpeackerMeeting]);

    // User Actions & Api Calls
    const updateState = (_state) => {
        setState((prevStates) => {
            return {
                ...prevStates,
                ..._state,
            };
        });
    };

    const closeModal = () => {
        localStorage.setItem("congratulations_popup", "false");
        setShowCongratulationsModal(false);
    };

    const closeSurveyModal = () => {
        localStorage.setItem("survey_popup", "false");
        setShowSurveyModal(false);
    };

    const getDashboardSession = (id = "") => {
        updateState({ loader: true });
        DashboardService.getDashboardSession(id, isPreRecordedSession)
            .then((res) => {
                setFlag(res?.data?.isMeetingScheduled);

                let _updateState = {
                    selectedKeyNote: "",
                    selectedPreRecordedSession: "",
                    selectedKeyNoteDiscussion: [],
                    loader: false,
                };

                if (isPreRecordedSession) {
                    _updateState = {
                        ..._updateState,
                        selectedPreRecordedSession: res?.data?.data,
                    };
                }
                if (res?.data?.data?.length > 0) {
                    _updateState = {
                        ..._updateState,
                        selectedKeyNote: res?.data?.data?.[0]?.scheduled_key_notes,
                        selectedKeyNoteDiscussion: res?.data?.data?.[0]?.play_book_course_questions,
                    };
                }
                updateState(_updateState);
            })
            .catch((e) => {
                updateState({ loader: false });
            });
    };

    const getOtherMeetingDetails = (requestType, id) => {
        updateState({ loader: true });
        DashboardService.getOtherMeetingDetails(requestType, id)
            .then((res) => {
                let _updateState = {
                    otherMeetingDetails: "",
                    loader: false,
                };
                if (!helpers.isEmpty(res?.data?.data?.meeting_details) && !helpers.isEmpty(res?.data?.data?.session)) {
                    _updateState = {
                        ..._updateState,
                        otherMeetingDetails: res?.data?.data,
                    };
                }
                updateState(_updateState);
            })
            .catch((e) => {
                updateState({ loader: false });
            });
    };

    const getPrevSessions = () => {
        updateState({ loader: true });
        getSessionsService
            .getSessions()
            .then((res) => {
                setUpcomingKeynote(res?.data?.data?.institution_key_notes);
                setSessionFlag(res?.data?.data?.isMeetingScheduled);
                let _updateState = {
                    loader: false,
                    previousSessions: [],
                    PreRecordedSessions: [],
                };
                if (res?.data?.data?.introduction_session) {
                    _updateState.PreRecordedSessions = res?.data?.data?.introduction_session;
                }
                if (res?.data?.data?.completed_key_notes) {
                    _updateState.previousSessions = res?.data?.data?.completed_key_notes;
                }
                updateState(_updateState);
            })
            .catch((e) => {
                updateState({ loader: false });
            });
    };

    const handleAddPostData = (id, data) => {
        let tempPlayBook = JSON.parse(JSON.stringify(state.selectedKeyNoteDiscussion));
        if (data) {
            tempPlayBook = tempPlayBook.map((p) => {
                if (p.id == id) {
                    p.community_discussion = data.data.data;
                }
                return p;
            });
            updateState({ selectedKeyNoteDiscussion: tempPlayBook });
        }
    };

    // Cache Values
    const isValidSelectedKeyNote = useMemo(() => {
        return state?.selectedKeyNote?.id && !helpers.isEmpty(state?.selectedKeyNote?.start_date) && !helpers.isEmpty(state?.selectedKeyNote?.end_date);
    }, [state?.selectedKeyNote]);

    upcomingKeynote?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    const filterMinDates = upcomingKeynote?.map((item) => item?.start_date);

    useEffect(() => {
        if (filterMinDates) {
            const filteredDates = filterMinDates.filter((date) => convertUtcToSelectedTimeZone(date) > new Date());

            filteredDates.sort((a, b) => new Date(a) - new Date(b));

            const secondSmallestDate = filteredDates[0];

            if (secondSmallestDate && flag) {
                dispatch(setUpcomingKeyNoteDate(secondSmallestDate));
            } else {
                dispatch(setUpcomingKeyNoteDate(null));
            }
        }
    }, [filterMinDates]);

    const handleFeedback = () => {
        if (masterClass === "Career Readiness") {
            window.open(" https://survey.alchemer.com/s3/7708768/Career-Readiness-Masterclass-Series-Presentation-Feedback", "_blank");
        } else {
            window.open("https://catapult.fillout.com/post-keynotesurvey", "_blank");
        }
    };

    return (
        <>
            <h3 className="d-lg-block d-none">{t("ASIDEBAR.DASHBOARD")}</h3>
            {state?.loader ? (
                <Loader />
            ) : (
                <>
                    {/* {showSurveyModal && !localStorage.getItem("survey_popup") && (
                        <>
                            <Confetti />
                            <CongratulationsSurveyModal onClose={closeSurveyModal} setShowSurveyModal={setShowSurveyModal} />
                        </>
                    )} */}
                    {showCongratulationsModal && !localStorage.getItem("congratulations_popup") && (
                        <>
                            <Confetti />
                            <CongratulationsModal onClose={closeModal} setShowCongratulationsModal={setShowCongratulationsModal} />
                        </>
                    )}
                    {!(isSuccessCoachMeeting || isSpeackerMeeting || isSpecialEvent) && (
                        <>
                            {isPreRecordedSession ? (
                                <>
                                    <div className="card-layout-video mt-4">
                                        <SessionComponent
                                            selectedSession={state.selectedPreRecordedSession}
                                            isPreRecordedSession={isPreRecordedSession}
                                            isPreviousSession={isPreviousSession}
                                            setIsPreRecordedSession={setIsPreRecordedSession}
                                            setIsPreviousSession={setIsPreviousSession}
                                        />
                                    </div>
                                    <button className="watch-session-btn mt-3" onClick={handleFeedback}>
                                        <MdOutlineRateReview className="fs-5 me-2" />
                                        Feedback
                                    </button>
                                </>
                            ) : (isValidSelectedKeyNote && flag === true) || isPreviousSession ? (
                                <>
                                    <div className="card-layout-video mt-4">
                                        <SessionComponent
                                            selectedSession={state.selectedKeyNote}
                                            isPreRecordedSession={isPreRecordedSession}
                                            isPreviousSession={isPreviousSession}
                                            setIsPreRecordedSession={setIsPreRecordedSession}
                                            setIsPreviousSession={setIsPreviousSession}
                                        />
                                    </div>
                                    <div className="text-end">
                                        <button className="feedback-session-btn mt-3" onClick={handleFeedback}>
                                            <MdOutlineRateReview className="fs-5 me-2" />
                                            Feedback
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <NoSessionsAvailable />
                                </>
                            )}
                            {isValidSelectedKeyNote && flag === true && (
                                <div className="card-layout mt-4">
                                    {state.selectedKeyNoteDiscussion?.length > 0 ? (
                                        state?.selectedKeyNoteDiscussion?.map((playBookQuestion, key) => {
                                            return (
                                                <div key={key} className={key > 0 ? "mt-4" : ""}>
                                                    <div className="card-layout questions-layout mt-2 mini-course-discussion">
                                                        <MiniCoursesDiscussion playBookQuestion={playBookQuestion} handleAddPostData={handleAddPostData} />
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center">Keynote Discussion Not Available</div>
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    {/* {(!(isSuccessCoachMeeting || isSpeackerMeeting || isSpecialEvent || isSession) || isDashboardPage || isPreRecordedSession || isPreviousSession) && userType !== 4 && (
                        <>
                            <div className="card-layout mt-4">
                                <PreRecordedSession
                                    sessionFlag={sessionFlag}
                                    sessions={state.PreRecordedSessions}
                                    setIsPreRecordedSession={setIsPreRecordedSession}
                                    setIsPreviousSession={setIsPreviousSession}
                                />
                            </div>
                        </>
                    )} */}

                    {(!(isSuccessCoachMeeting || isSpeackerMeeting || isSpecialEvent || isSession) || isDashboardPage || isPreRecordedSession || isPreviousSession) && userType !== 4 && (
                        <>
                            <div className="card-layout mt-4">
                                <PreviousSessions
                                    sessionFlag={sessionFlag}
                                    sessions={state.previousSessions}
                                    setIsPreRecordedSession={setIsPreRecordedSession}
                                    setIsPreviousSession={setIsPreviousSession}
                                />
                            </div>
                        </>
                    )}
                    {(isSuccessCoachMeeting || isSpeackerMeeting || isSpecialEvent) && (
                        <>
                            {state?.otherMeetingDetails && (
                                <>
                                    <div className="card-layout-video mt-4">
                                        <CommonZoomPlayComponent
                                            zoomSession={state.otherMeetingDetails.session}
                                            meetingDetails={{
                                                meetingId: state.otherMeetingDetails.meeting_details?.meetingId,
                                                userName: `${authUser?.first_name} ${authUser?.last_name}`,
                                                userId: `${authUser.id}`,
                                                zoomMeetingPassword: state.otherMeetingDetails.meeting_details?.zoomMeetingPassword,
                                                userEmail: authUser?.email,
                                                zoomAccessToken: state.otherMeetingDetails.meeting_details?.zoomAccessToken,
                                                role: isSpecialEvent ? 0 : 1,
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Dashboard;
