import React from "react";
import "../community.css";
import DiscussionAccordion from "./discussion-accordion";

const Discussion = ({ isFirst, discussion, handleAddPostData, getCommunity }) => {
    return (
        <>
            {discussion.play_book_course_questions.map((question, key) => (
                <React.Fragment key={key}>
                    {question.add_pre_recorded_session === 0 ? (
                        <DiscussionAccordion question={question} handleAddPostData={handleAddPostData} getCommunity={getCommunity} />
                    ) : question.full_video_path !== null ? (
                        <>
                            <div className="position-relative text-center d-flex justify-content-center align-items-center w-100">
                                <video crossOrigin="anonymous" controls className="mt-4 community-question-video">
                                    <source crossOrigin="anonymous" src={question.full_video_path} />
                                </video>
                            </div>
                        </>
                    ) : (
                        <div className="position-relative text-center d-flex justify-content-center align-items-center w-100">
                            <iframe
                                title={`YouTube Video ${key}`}
                                width="560"
                                height="315"
                                src={question.video_url}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="mt-4 community-question-video"
                            ></iframe>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </>
    );
};
export default Discussion;
