import React, { useState } from "react";
import * as Yup from "yup";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { loginService } from "../../../../services/authService/loginService";
import { toast } from "react-toastify";
import { loginSuccess, setAccessToken, setUserData } from "../../../../redux/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { profileSettingService } from "../../../../services/profileSettingService/profileSettingService";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

const LoginContentForm = () => {
    // states start
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userType, setUserType] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const pathname = location.pathname;
    const segments = pathname.split("/");
    const event = segments[2];
    // states end

    // initial values of all fields
    const initialValues = {
        device_type: "",
        email: "",
        password: "",
        device_token: "",
    };

    // Define a separate schema for password validation
    const passwordValidationSchema = Yup.object().shape({
        password: Yup.string()
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .required(t("COMMON.PASSWORD.REQUIRED")),
    });

    // Define the main validation schema
    const generalValidationSchema = Yup.object().shape({
        email: Yup.string()
            .transform((value) => value.trimEnd())
            .test("noLeadingSpaces", "Invalid Input, Please avoid leading spaces at the beginning of the field", (value) => {
                return !value || value.trimStart() === value;
            })
            .email(t("COMMON.EMAIL.INVALID_FORMAT"))
            .required(t("COMMON.EMAIL.REQUIRED")),
    });

    // function for submit all values
    const onSubmit = (values) => {
        setIsSubmitting(true);
        loginService
            .login(values)
            .then((response) => {
                if (response?.data?.data?.token) {
                    localStorage.setItem("access_token", response.data?.data?.token);
                    localStorage.setItem("masterClass", response.data?.data?.masterclass);
                    dispatch(setAccessToken(response.data?.data?.token));
                    const user = response?.data?.data?.user;
                    toast.success(response?.data?.message);
                    dispatch(loginSuccess(user));
                    localStorage.setItem("user", JSON.stringify(user));
                    profileSettingService.getUserProfileData(user?.id).then((response) => {
                        const fetchedUserData = response?.data?.data;
                        dispatch(setUserData(fetchedUserData));
                        navigate("/dashboard");
                    });

                    if (params?.id && event !== null) {
                        profileSettingService.getUserProfileData(user?.id).then((response) => {
                            const fetchedUserData = response?.data?.data;
                            dispatch(setUserData(fetchedUserData));
                            navigate(`/dashboard/${event}/${params?.id}`);
                        });
                    }
                } else {
                    if (response?.data?.data?.user_type) {
                        setUserType(response?.data?.data?.user_type);
                    } else {
                        toast.error(response?.data?.message);
                    }
                }
            })
            .catch((ex) => {
                if (ex?.response?.data?.data?.user_type) {
                    setUserType(ex?.response?.data?.data?.user_type);
                } else {
                    toast.error(ex?.response?.data?.message);
                }
            });
    };

    // function for hide password field
    const handlePassword = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: () => (userType === 3 || userType === 4 ? generalValidationSchema.concat(passwordValidationSchema) : generalValidationSchema),
        onSubmit,
    });

    return (
        <>
            {/*  JSX code for login form start */}
            <div className="login-form-content">
                <div className="login-form-title mb-4 mb-sm-5 ">{t("COMMON.LOGIN")}</div>
                <form onSubmit={formik.handleSubmit} className="login-form-field">
                    {/* email input field start */}
                    <div className="form-group mb-4">
                        {userType !== 3 && userType !== 4 ? (
                            <input
                                type="text"
                                id="email"
                                name="email"
                                className="form-control login-form-group"
                                placeholder={t("COMMON.EMAIL.ADDRESS")}
                                value={formik.values.email}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                            />
                        ) : (
                            <div className="form-control login-form-group">{formik.values.email}</div>
                        )}
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger" style={{ fontSize: "15px" }}>
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>
                    {/* email input field end  */}

                    {/* password input field start  */}
                    {(userType === 3 || userType === 4) && (
                        <div className="form-group mb-4 position-relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                placeholder="Password"
                                className="form-control login-form-group"
                                value={formik.values.password}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setIsSubmitting(false);
                                }}
                                onBlur={formik.handleBlur}
                            />
                            <button className="btn position-absolute top-0 end-0" type="button" id="show-password" onClick={handlePassword} style={{ height: "48px" }}>
                                {showPassword ? <AiFillEye className="header-icon" /> : <AiFillEyeInvisible className="header-icon" />}
                            </button>
                            {formik.touched.password && formik.errors.password ? (
                                <div className="text-danger" style={{ fontSize: "15px" }}>
                                    {formik.errors.password}
                                </div>
                            ) : null}
                        </div>
                    )}
                    {/* password input field end  */}
                    {/* login button start  */}
                    <button type="submit" className="btn login-button" disabled={!formik.dirty || isSubmitting}>
                        {t("COMMON.LOGIN")}
                    </button>
                    {/* login button end  */}
                </form>
            </div>
            {/*  JSX code for login form end */}
        </>
    );
};

export default LoginContentForm;
