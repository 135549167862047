import apiClient from "../../utils/apiClient";

const RESEND_OTP = "/resend_otp";

const verifyResendOtp = (data) => {
  const url = `${RESEND_OTP}`;
  return apiClient.post(url, data);
};

export const resendOtpService = {
  verifyResendOtp,
};
