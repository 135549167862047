import React from "react";
import { FiCalendar } from "react-icons/fi";
import { PiPlayBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MEETING_TYPES, ROUTES } from "../../constants";

const PreviosSessionCard = ({ data, setIsPreviousSession, setIsPreRecordedSession }) => {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleClick = () => {
        setIsPreRecordedSession(false);
        setIsPreviousSession(true);
        navigate("/" + ROUTES.meetingPage.replace(":type", MEETING_TYPES.previousSession).replace(":id", data.key_note_id));
        scrollToTop();
    };

    return (
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12 mb-4">
            <div style={{ padding: "0px 10px" }}>
                <div className="position-relative text-center d-flex justify-content-center align-items-center" onClick={handleClick}>
                    <img crossOrigin="anonymous" src={data.key_note.full_cover_image} className="previous-session-thumbnail position-relative" alt={data.key_note.name} />
                    <button className="video-player-button">
                        <PiPlayBold className="d-flex justify-content-center align-items-center" />
                    </button>
                </div>
                <div className="card-body">
                    <h6 className="previous-session-card-title mt-4 ">{data.key_note.name}</h6>
                    {/* <div className="d-flex justify-content-between align-items-center">
                        <div className="gap-2 previous-session-date">
                            <FiCalendar />
                            <p className="card-text">{moment(data.start_date).format("DD MMM, yyyy")}</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default PreviosSessionCard;
