import React, { useState, useEffect } from "react";
import "./community.css";
import Discussion from "./components/discussion";
import { communityService } from "../../services/communityService/communityService";
import Loader from "../../components/common/Loader";
import Pagination from "@mui/material/Pagination";
import notFound from "../../assets/not-found/not_found.png";
import Select from "react-select";

const Community = () => {
    const limit = 5;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [discussions, setDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedKeyNote, setSelectedKeyNote] = useState(0);
    const [keyNotes, setKeyNotes] = useState([]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const getCommunity = () => {
        setIsLoading(true);
        communityService.getCommunity(page, limit, selectedKeyNote).then((res) => {
            const groupedDiscussions = groupDiscussions(res?.data?.data);
            setDiscussions(groupedDiscussions);
            setIsLoading(false);
            setTotal(Math.ceil(res?.data?.total_records / limit));
        });
    };

    const groupDiscussions = (discussions) => {
        const groupedDiscussions = {};
        discussions.forEach((discussion) => {
            const key = `${discussion.key_note.name}-${discussion.play_book_course.name}`;
            if (!groupedDiscussions[key]) {
                groupedDiscussions[key] = [];
            }
            groupedDiscussions[key].push(discussion);
        });

        return Object.values(groupedDiscussions);
    };

    const handleAddPostData = (questionId, data) => {
        let tempDiscussion = JSON.parse(JSON.stringify(discussions));
        if (data) {
            tempDiscussion = tempDiscussion.map((group) => {
                return group.map((discussion) => {
                    discussion.play_book_course_questions = discussion.play_book_course_questions.map((question) => {
                        if (question.id === questionId) {
                            question.community_discussion = data.data.data;
                        }
                        return question;
                    });
                    return discussion;
                });
            });
            setDiscussions(tempDiscussion);
        }
    };

    useEffect(() => {
        getCommunity();
    }, [page, selectedKeyNote]);

    const getKeyNotes = () => {
        setIsLoading(true);

        communityService.getAllKeyNoteList().then((res) => {
            setKeyNotes(res?.data?.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getKeyNotes();
    }, []);

    const handleKeyNoteChange = (e) => {
        setSelectedKeyNote(e.value);
        setPage(1);
    };

    return (
        <>
            <div className="d-flex flex-column gap-3">
                <h3 className="d-lg-block d-none">Keynote Discussion</h3>
                <div className="d-flex align-center">
                    <div className="d-flex flex-column flex-md-row flex-lg-row gap-10 align-items-start align-items-lg-center align-items-md-center">
                        <h5 className="m-0">Keynotes:</h5>
                        <Select
                            onChange={(selectedOption) => handleKeyNoteChange(selectedOption)}
                            options={keyNotes.map((keyNote) => ({
                                value: keyNote.id,
                                label: keyNote.name,
                            }))}
                            placeholder="Select keynotes "
                        />
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="outer-card-layout mt-4 px-4 pt-1 pb-4">
                        {discussions?.length > 0 ? (
                            <>
                                {discussions.map((discussionGroup, groupIndex) => {
                                    const firstDiscussion = discussionGroup[0];
                                    return (
                                        <div key={groupIndex}>
                                            <div className={groupIndex === 0 ? "heading mt-3" : "heading mt-2"}>{firstDiscussion.key_note.name}</div>
                                            <div className="heading-2 mt-2">{firstDiscussion.play_book_course.name}</div>
                                            {discussionGroup.map((discussion, key) => (
                                                <Discussion
                                                    key={key}
                                                    isFirst={groupIndex === 0 && key === 0}
                                                    discussion={discussion}
                                                    handleAddPostData={handleAddPostData}
                                                    getCommunity={getCommunity}
                                                />
                                            ))}
                                            {groupIndex !== discussions.length - 1 && <hr style={{ marginBottom: ".5rem" }} />}
                                        </div>
                                    );
                                })}
                                {total > 0 && (
                                    <div className="pagination-div">
                                        <Pagination count={total} page={page} onChange={handleChange} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="w-100 mt-3 rounded-4 text-center d-flex flex-column align-items-center justify-content-center" style={{ height: "400px" }}>
                                <img crossOrigin="anonymous" src={notFound} alt="data not found" width={200} />
                                <h4>No Keynote Discussion Added</h4>
                                <p className="fs-6">No Keynote Discussion available at the moment.</p>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Community;
