import usIcon from "../../assets/languages/us.svg";
import frIcon from "../../assets/languages/fr.svg";

export const languagesData = [
  {
    value: "en",
    label: "English (US)",
    flagIcon: usIcon,
  },
  {
    value: "es",
    label: "French",
    flagIcon: frIcon,
  },
];
