import React, { useEffect, useState } from "react";
import selectIcon from "../../../assets/login/select_icon.png";
import { useTranslation } from "react-i18next";
import { languagesData } from "../../../lib/languages/languages";

const LanguageContentSection = () => {
  // states start
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const { i18n } = useTranslation();
  // states end

  // useEffect to set the language on component mount
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  //function for changes language
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
    window.location.reload();
  };

  return (
    <>
      <div className="dropdown language-dropdown">
        <button
          className="btn select-language"
          type="button"
          id="languageDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {
            languagesData.find(
              (language) => language.value === selectedLanguage
            )?.label
          }
        </button>
        <ul className="dropdown-menu  " aria-labelledby="languageDropdown">
          {languagesData.map((language) => (
            <li key={language.value}>
              <button
                className="dropdown-item d-flex gap-3"
                onClick={() =>
                  handleLanguageChange({
                    target: { value: language.value },
                  })
                }
              >
                <img
                  crossOrigin="anonymous"
                  src={language.flagIcon}
                  alt="Flag"
                  className="flag-icon"
                />
                <span>{language.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="select-icon">
        <img crossOrigin="anonymous" src={selectIcon} alt="icon" />
      </div>
    </>
  );
};

export default LanguageContentSection;
