import React from "react";

import UnlockedSessionCard from "../SessionCards/UnlockedSessionCard";

const UnlockedSessions = ({ completedSessions, setIsPreviousSession, flag }) => {
    return (
        <div className="pb-3 pe-3">
            {completedSessions?.map((session) => {
                return (
                    <React.Fragment key={session?.id}>
                        <UnlockedSessionCard session={session} setIsPreviousSession={setIsPreviousSession} flag={flag} />
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default UnlockedSessions;
