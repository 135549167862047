import React from "react";
import { FiChevronLeft, FiChevronRight, FiSearch } from "react-icons/fi";

const CustomToolbar = (props) => {
    const handleViewChange = (view) => {
        props.onViewChange(view);
    };

    return (
        <>
            <div className="text-gray-800 fw-bold" style={{ padding: "10px" }}>
                <div className="rbc-toolbar d-sm-flex d-none">
                    <div className="rbc-btn-group appointments-btn-group">
                        <button className="text-gray-700 fw-bold btn middle-btn" onClick={() => props.onViewChange("TODAY")} type="button">
                            Today
                        </button>
                    </div>

                    <div className="rbc-btn-group appointments-btn-group">
                        <button className={`text-gray-700 btn fw-bold view-btn ${props.view === "day" ? "active" : ""}`} onClick={() => handleViewChange("day")} type="button">
                            Day
                        </button>

                        <button className={`text-gray-700 btn fw-bold view-btn ${props.view === "week" ? "active" : ""}`} onClick={() => handleViewChange("week")} type="button">
                            Week
                        </button>

                        <button className={`text-gray-700 btn fw-bold view-btn ${props.view === "month" ? "active" : ""}`} onClick={() => handleViewChange("month")} type="button">
                            Month
                        </button>
                    </div>

                    <div className="search-container">
                        <input placeholder="Search" className="search-input" />
                        <FiSearch className="search-icon" />
                    </div>
                </div>
                <div className="rbc-toolbar d-sm-none">
                    <div className="rbc-btn-group appointments-btn-group">
                        <button
                            className={`text-gray-700 btn fw-bold view-btn ${props.view ? "active" : ""}`}
                            onClick={() => {
                                props.onViewChange("day");
                            }}
                            type="button"
                        >
                            Day
                        </button>

                        <button className="text-gray-700 fw-bold btn view-btn" onClick={() => props.onViewChange("week")} type="button">
                            Week
                        </button>
                        <button className="text-gray-700 btn fw-bold view-btn" onClick={() => props.onViewChange("month")} type="button">
                            Month
                        </button>
                    </div>
                    <div className="d-flex w-50 justify-content-between btn-sm-group">
                        <div className="rbc-btn-group appointments-btn-group">
                            <button className="text-gray-700 fw-bold btn middle-btn" onClick={() => props.onViewChange("TODAY")} type="button">
                                Today
                            </button>
                        </div>

                        <div className="search-container">
                            <input placeholder="Search" className="search-input" />
                            <FiSearch className="search-icon" />
                        </div>
                    </div>
                </div>
                <div className="rbc-toolbar mt-4">
                    <button className="text-gray-700 fw-bold btn left-arrow-btn" onClick={() => props.onViewChange("PREV")} type="button">
                        <FiChevronLeft />
                    </button>
                    <div className="rbc-toolbar-label">{props.label}</div>
                    <button className="text-gray-700 fw-bold btn right-arrow-btn" onClick={() => props.onViewChange("NEXT_MONTH")} type="button">
                        <FiChevronRight />
                    </button>
                </div>
            </div>
        </>
    );
};

export { CustomToolbar };
