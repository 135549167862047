import apiClient from "../../utils/apiClient";

const FORGOT_DATA = "/forgot_password";

const addForgotData = (data) => {
  const url = `${FORGOT_DATA}`;
  return apiClient.post(url, data);
};

export const forgotService = {
  addForgotData,
};
