import React, { useEffect, useState } from "react";
import postIcon from "../../../assets/mini-courses/Chat_Conversation_Circle.svg";
import Conversation from "../../../assets/conversations.svg";
import Replies from "./replies";
import ReplyIcon from "../../../assets/reply2.svg";
import AddPost from "../modal/add-post";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { miniBookService } from "../../../services/miniBookService/miniBookService";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const Comments = ({ discussion, handleAddPostData, getCommunity }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [tempDiscussion, setTempDiscussion] = useState();
    const userData = useSelector((state) => state?.auth?.userData);

    const handleAddPost = () => {
        setShowAddModal(true);
    };
    const handleEditPost = () => {
        setShowEditModal(true);
    };

    const closeAddPost = (data) => {
        setShowAddModal(false);
        handleReload(data);
    };

    const handleCloseEditPost = (data) => {
        setShowEditModal(false);
        handleReload(data);
    };

    const handleReload = (data) => {
        handleAddPostData(discussion.discussion.play_book_course_question_id, data);
    };

    const renderAnswer = (answer) => {
        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const parts = answer.split(linkRegex);
        return parts.map((part, index) => {
            if (part.match(linkRegex)) {
                return (
                    <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="fw-bold">
                        {part}
                    </a>
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    const handleLike = () => {
        miniBookService.addLike(tempDiscussion?.discussion.id).then((res) => {
            handleAddPostData(tempDiscussion?.discussion.play_book_course_question_id, res);
        });
    };

    useEffect(() => {
        setTempDiscussion(discussion);
    }, [discussion]);

    const handleDeleteConfirmation = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete();
            }
        });
    };

    const handleDelete = () => {
        miniBookService
            .deletePlaybookDiscussion(tempDiscussion?.discussion.id)
            .then((res) => {
                if (res) {
                    Swal.fire("Deleted!", "Your post has been deleted.", "success");
                    getCommunity();
                }
            })
            .catch((error) => {
                Swal.fire("Error!", "Failed to delete the post.", "error");
            });
    };
    return (
        <>
            <div className="comments-display">
                <div className="d-flex flex-row mt-2">
                    <div className="post-icon-w">
                        <img crossOrigin="anonymous" src={postIcon} alt="post-icon" />
                    </div>
                    <div className="d-flex flex-lg-row w-100 justify-space-between align-center">
                        <div className="discription">
                            <h6>{renderAnswer(discussion.discussion.title)}</h6>
                            <p className="m-text" style={{ wordBreak: "break-all" }}>
                                {renderAnswer(discussion.discussion.answer)}
                            </p>
                        </div>
                        <div className="d-flex align-items-end flex-column justify-content-end gap-1">
                            <div className="d-flex align-items-center justify-content-end gap-1" style={{ flex: "0 0 20%" }}>
                                <div className="cursor-pointer align-center d-flex" onClick={() => handleLike()}>
                                    {/* <img src={ThumpsUp}></img> */}
                                    {tempDiscussion?.discussion?.likes > 0 ? (
                                        <div style={{ fontSize: "12px", marginRight: "5px" }} className="wd-35">
                                            {tempDiscussion?.discussion?.likes + " " + (tempDiscussion?.discussion?.likes > 1 ? "Likes" : "Like")}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {tempDiscussion?.discussion?.hasUserLiked ? (
                                        <>
                                            <AiFillLike />
                                        </>
                                    ) : (
                                        <>
                                            <AiOutlineLike />
                                        </>
                                    )}
                                </div>
                                <div className="chat-icon">
                                    <img crossOrigin="anonymous" src={Conversation} alt="chat-icon" style={{ width: "22px" }}></img>
                                    <div className="replies-count">{discussion.replies.length}</div>
                                </div>
                            </div>
                            {discussion?.discussion?.user_id === userData?.id && (
                                <div className="d-flex gap-1">
                                    <button className="border-0 p-0" style={{ background: "unset", color: "#bc9c6c" }} onClick={() => handleEditPost()}>
                                        <FaRegEdit fontSize={20} />
                                    </button>
                                    <button className="border-0 p-0" style={{ background: "unset", color: "rgba(139, 0, 50, 1)" }} onClick={handleDeleteConfirmation}>
                                        <MdDelete fontSize={24} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row mb-2 cursor-pointer" style={{ marginLeft: "30px" }} onClick={() => handleAddPost()}>
                    <img src={ReplyIcon}></img>

                    <span className="reply-t">Reply</span>
                </div>
                {discussion.replies.length > 0 && (
                    <>
                        {discussion.replies.map((reply, key) => {
                            return <Replies discussion={reply} key={key} handleAddPostData={handleAddPostData} getCommunity={getCommunity} />;
                        })}
                        <div className="comments-border mt-2"></div>
                    </>
                )}
            </div>
            {discussion.replies.length == 0 && <div className="comments-border"></div>}
            {showAddModal && <AddPost onClose={closeAddPost} questionId={discussion.discussion.play_book_course_question_id} parentId={discussion.discussion.id} isReply={true} />}
            {showEditModal && (
                <AddPost
                    onClose={handleCloseEditPost}
                    questionId={discussion.discussion.play_book_course_question_id}
                    isReply={false}
                    isComment={true}
                    isEdit={true}
                    discussionId={discussion?.discussion?.id}
                    getCommunity={getCommunity}
                />
            )}
        </>
    );
};

export default Comments;
