import { useEffect, useRef, useState } from "react";

const VideoTrackerPercentage = () => {
    const videoRef = useRef(null);
    const [percentageWatched, setPercentageWatched] = useState(0);
    const [integerDuration, setIntegerDuration] = useState(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        const handleTimeUpdate = () => {
            const { currentTime, duration } = videoElement;
            const newPercentage = (currentTime / duration) * 100;
            setPercentageWatched(newPercentage);

            const floatValue = duration;
            const integerValue = Math.floor(floatValue);
            setIntegerDuration(integerValue);
        };

        videoElement.addEventListener("timeupdate", handleTimeUpdate);

        return () => {
            videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, []);

    return {
        videoRef,
        integerDuration,
        percentageWatched,
    };
};

export default VideoTrackerPercentage;
