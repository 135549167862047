const calculateTimeDifference = (createdAt) => {
    const currentTime = new Date();
    const commentTime = new Date(createdAt);
    const timeDifference = currentTime - commentTime;

    if (timeDifference < 60000) {
        return "just now";
    }

    // Calculate time units
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
};

export default calculateTimeDifference;
