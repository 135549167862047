import moment from "moment";
import UpcomingSessionCountdown from "../UpcomingSessionCountdown/UpcomingSessionCountdown";
import ZoomMeetPage from "./Zoom";
import ZoomImage from "../../assets/Zoom_Communications_Logo.png";
import { useEffect, useMemo, useState } from "react";
import helpers from "../../utils/common-functions/Helper";

const CommonZoomPlayComponent = ({ zoomSession, meetingDetails }) => {
    const [meetingStartAndEndConfig, setMeetingStartAndEndConfig] = useState(null);
    const [clickEvent, setClickEvent] = useState(false);

    useEffect(() => {
        const reCheckTime = () => {
            setMeetingStartAndEndConfig((prevStates) => {
                return {
                    ...prevStates,
                    isStart: moment().tz("UTC").isAfter(moment.utc(zoomSession.start_date)),
                    // isEnd: moment().tz("UTC").isAfter(moment.utc(zoomSession.end_date)),
                };
            });
        };

        reCheckTime();
        const intervalId = setInterval(reCheckTime, 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [zoomSession]);

    const isNotEnd = useMemo(() => {
        return meetingStartAndEndConfig?.isStart && !moment().tz("UTC").isAfter(moment.utc(zoomSession.end_date));
    }, [meetingStartAndEndConfig?.isStart, zoomSession.end_date]);

    if (helpers.isEmpty(meetingStartAndEndConfig)) {
        return;
    }

    return (
        <>
            {meetingStartAndEndConfig.isStart ? (
                <>
                    {(() => {
                        if (zoomSession?.meeting_details_id) {
                            if (isNotEnd) {
                                return (
                                    <>
                                        <div className={`${clickEvent ? "zoom-card-background" : "zoom-card"}`}>
                                            <div className={`column ${clickEvent ? "d-flex" : "d-none"}`} id="meetingSDKElement"></div>
                                            <ZoomMeetPage video={meetingDetails} setClickEvent={setClickEvent} clickEvent={clickEvent} />
                                        </div>
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <div className="zoom-card">
                                            <div className="zoom-cntr">
                                                <img src={ZoomImage} className="zoom-img" alt="Zoom"></img>
                                                <div className="zoom-end-text mt-4">This meeting has been ended</div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        } else {
                            return null;
                        }
                    })()}
                </>
            ) : (
                <UpcomingSessionCountdown sessionStartTime={zoomSession.start_date} title="Meeting will start within" />
            )}
        </>
    );
};

export default CommonZoomPlayComponent;
