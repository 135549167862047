import React, { useState } from "react";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";
import Comments from "./comments";

const ShowAllDiscussionPosts = ({ communityDiscussion, handleAddPostData, getCommunity }) => {
    const [showActivity, setShowActivity] = useState(true);

    const toggleActivity = () => {
        setShowActivity(!showActivity);
    };
    return (
        <>
            {communityDiscussion.length > 0 && (
                <div className="post-container mt-2">
                    <div className="d-flex align-items-center mt-2 display-all-post">
                        {!showActivity ? (
                            <div className="activity-toggle" onClick={toggleActivity}>
                                <p className="m-0 show-all">Show All Posts</p>
                                <PiCaretUpBold />
                            </div>
                        ) : (
                            <div className="activity-toggle" onClick={toggleActivity}>
                                <p className="m-0 show-all">Hide All Posts</p>
                                <PiCaretDownBold />
                            </div>
                        )}
                    </div>
                    {showActivity &&
                        communityDiscussion.map((discussion, key) => {
                            return <Comments key={key} discussion={discussion} handleAddPostData={handleAddPostData} getCommunity={getCommunity} />;
                        })}
                </div>
            )}
            {communityDiscussion.length == 0 && (
                <>
                    <div style={{ marginTop: "20px", fontSize: "16px" }}> No Posts </div>
                </>
            )}
        </>
    );
};

export default ShowAllDiscussionPosts;
