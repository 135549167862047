/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

const ShowAllEvents = ({ events, showAll, onClose, handleEventClick }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        // start modal
        <Modal show={showAll}>
            {/* start modal header  */}
            <Modal.Header>
                <h5>
                    All Availabilities of&nbsp;
                    {events && events.length > 0 ? moment(events?.[0]?.start).format("LL") : ""}
                </h5>
                <a className="tu-close " type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <AiOutlineClose fontSize={24} style={{ color: "black" }} />
                </a>
            </Modal.Header>
            {/* end modal header  */}
            {/* start modal body  */}
            <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
                <div className="row">
                    <div className="d-flex flex-wrap justify-content-between">
                        {events &&
                            events.map((event, index) => (
                                <div
                                    className={`event-popup w-100 mb-3 gap-6 ${event?.type === "meeting" ? "meeting-event" : ""} ${event?.type === "availability" ? "availability-event" : ""} 
                  ${event?.type === "speaker_availability_meetings" ? "speaker-meeting-event" : ""} ${event?.type === "speaker_availability" ? "speaker-availability" : ""}`}
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    onClick={() => handleEventClick(event)}
                                >
                                    <div className="event-info">
                                        <div>
                                            <h5>{event?.title}</h5>
                                        </div>
                                    </div>
                                    <div className="event-info" style={{ flex: "0px" }}>
                                        <p>
                                            Meeting Time: {moment(event?.start).format("hh:mm A")}&nbsp; - &nbsp;
                                            {moment(event?.end).format("hh:mm A")}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Modal.Body>
            {/* end modal body  */}
        </Modal>
        // end modal
    );
};

export default ShowAllEvents;
