/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

const YoutubeVideo = ({ videoUrl, id, updateQuestionAttendance }) => {
    const percentageWatchedRef = useRef(0);
    const totalDurationRef = useRef(0);
    const playerRef = useRef(null);
    const intervalRef = useRef(null);
    const hasUpdatedAttendanceRef = useRef(false);

    const extractYouTubeVideoId = (url) => {
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(youtubeRegex);
        return match ? match[4] : null;
    };

    const extractVimeoVideoId = (url) => {
        const vimeoRegex = /(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/;
        const match = url.match(vimeoRegex);
        return match ? match[1] : null;
    };

    const isYouTubeVideo = videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be");
    const isVimeoVideo = videoUrl.includes("vimeo.com") || videoUrl.includes("player.vimeo.com");

    const videoId = isYouTubeVideo ? extractYouTubeVideoId(videoUrl) : isVimeoVideo ? extractVimeoVideoId(videoUrl) : null;

    const handleTimeUpdate = () => {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();

        const percentage = (currentTime / duration) * 100;
        percentageWatchedRef.current = percentage;

        if (percentage >= 90 && playerRef.current.getPlayerState() !== window.YT.PlayerState.ENDED) {
            clearInterval(intervalRef.current);
            updateQuestionAttendance();
        }
    };

    const handleTimeUpdateVimeo = async () => {
        if (playerRef.current) {
            try {
                const currentTimePromise = playerRef.current.getCurrentTime();
                const durationPromise = playerRef.current.getDuration();
                const [currentTime, duration] = await Promise.all([currentTimePromise, durationPromise]);
                const vimeoPercentage = (currentTime / duration) * 100;
                percentageWatchedRef.current = vimeoPercentage;
                totalDurationRef.current = duration;
                if (vimeoPercentage >= 90 && !hasUpdatedAttendanceRef.current) {
                    clearInterval(intervalRef.current);
                    updateQuestionAttendance();
                    hasUpdatedAttendanceRef.current = true;
                }
            } catch (error) {
                console.error("Error in handleTimeUpdateVimeo:", error);
            }
        }
    };

    useEffect(() => {
        const loadYouTubeAPI = () => {
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName("script")[0];

            const handleStateChange = (stateEvent) => {
                if (stateEvent.data === window.YT.PlayerState.PLAYING) {
                    intervalRef.current = setInterval(handleTimeUpdate, 1000);
                } else {
                    clearInterval(intervalRef.current);
                }
            };

            const onYouTubeIframeAPIReady = () => {
                playerRef.current = new window.YT.Player("video-player", {
                    videoId: videoId,
                    events: {
                        onReady: (event) => {
                            event.target.addEventListener("onStateChange", handleStateChange);
                        },
                    },
                });
            };

            if (window.YT && window.YT.Player) {
                onYouTubeIframeAPIReady();
            } else {
                window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
        };

        const loadVimeoAPI = () => {
            const script = document.createElement("script");
            script.src = "https://player.vimeo.com/api/player.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                const options = {
                    id: videoId,
                    loop: false,
                };

                playerRef.current = new window.Vimeo.Player("video-player", options);

                playerRef.current.on("play", () => {
                    intervalRef.current = setInterval(handleTimeUpdateVimeo, 1000);
                });
            };
        };

        if (isYouTubeVideo) {
            loadYouTubeAPI();
        } else if (isVimeoVideo) {
            loadVimeoAPI();
        }

        return () => {
            if (window.YT && window.YT.Player && isYouTubeVideo) {
                if (playerRef.current) {
                    playerRef.current.destroy();
                }
                clearInterval(intervalRef.current);
            } else if (window.Vimeo && isVimeoVideo) {
                if (playerRef.current) {
                    playerRef.current.destroy();
                }
                clearInterval(intervalRef.current);
            }
            hasUpdatedAttendanceRef.current = false;
        };
    }, [videoUrl, videoId, isYouTubeVideo, isVimeoVideo]);

    return (
        <>
            <div id="video-player" className="playbook-question-video vimeo-video"></div>
        </>
    );
};

export default YoutubeVideo;
