import React from "react";
import "./loader.css";
import { RotatingLines } from "react-loader-spinner";

const FileUploadedLoader = () => {
    return (
        <>
            <div className="file-loader-container">
                <RotatingLines
                    height={50}
                    width={50}
                    color="white"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                    strokeColor="grey"
                    animationDuration="0.75"
                />
            </div>
        </>
    );
};

export default FileUploadedLoader;
