import React, { useState } from "react";
import { useSelector } from "react-redux";
import PocScheduleCalendar from "./Components/PocScheduleCalendar";
import SpeakerScheduleCalendar from "./Components/SpeakerScheduleCalendar";
import StudentScheduleCalendar from "./Components/StudentScheduleCalendar";
import moment from "moment";
import InnerLayout from "../../layout/InnerLayout";
import Loader from "../../components/common/Loader";

const formats = {
  dayFormat: (date, culture, localizer) => {
    const dayOfWeek = localizer.format(date, "ddd");
    const dayOfMonth = localizer.format(date, "DD");

    return `${dayOfWeek}\n${dayOfMonth}`;
  },
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
    const startDate = localizer.format(start, "MMM Do YYYY");
    const endDate = localizer.format(end, "MMM Do YYYY");
    return `${startDate} - ${endDate}`;
  },
  dayHeaderFormat: (date, culture, localizer) => {
    return localizer.format(date, "MMM Do YYYY");
  },
};

const ScheduleCalendar = () => {
  // start states
  const userType = useSelector((state) => state?.auth?.userData?.user_type);
  const [currentDate, setCurrentDate] = useState(moment());
  const [currentView, setCurrentView] = useState("week");
  const [isLoading, setIsLoading] = useState(false);
  // end states

  // functions start for all toolbar buttons
  const handleNextButtonClick = () => {
    if (currentView === "month") {
      const nextMonth = moment(currentDate).add(1, "month");
      setCurrentDate(nextMonth);
    } else if (currentView === "week") {
      const nextWeek = moment(currentDate).add(1, "week");
      setCurrentDate(nextWeek);
    } else if (currentView === "day") {
      const nextDay = moment(currentDate).add(1, "day");
      setCurrentDate(nextDay);
    } else if (currentView === "agenda") {
      const nextAppoinment = moment(currentDate).add(1, "month");
      setCurrentDate(nextAppoinment);
    }
  };
  const handlePrevButtonClick = () => {
    if (currentView === "month") {
      const pervMonth = moment(currentDate).add(-1, "month");
      setCurrentDate(pervMonth);
    } else if (currentView === "week") {
      const pervWeek = moment(currentDate).add(-1, "week");
      setCurrentDate(pervWeek);
    } else if (currentView === "day") {
      const pervDay = moment(currentDate).add(-1, "day");
      setCurrentDate(pervDay);
    } else if (currentView === "agenda") {
      const pervAppoinment = moment(currentDate).add(-1, "month");
      setCurrentDate(pervAppoinment);
    }
  };
  const handleTodayButtonClick = () => {
    if (currentView === "month") {
      setCurrentDate(moment());
    } else if (currentView === "week") {
      setCurrentDate(moment());
    } else if (currentView === "day") {
      setCurrentDate(moment());
    } else if (currentView === "agenda") {
      setCurrentDate(moment());
    }
  };
  const handleViewChange = (view) => {
    if (view === "NEXT_MONTH") {
      handleNextButtonClick();
    } else if (view === "PREV") {
      handlePrevButtonClick();
    } else if (view === "TODAY") {
      handleTodayButtonClick();
    } else {
      setCurrentView(view);
    }
  };
  // functions end for all toolbar buttons

  // Define a function to customize the styles of day cells
  const dayPropGetter = (date) => {
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return {
        style: {
          backgroundColor: "#546982",
        },
      };
    }

    return {};
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : userType === 3 ? (
        <PocScheduleCalendar
          formats={formats}
          currentView={currentView}
          currentDate={currentDate}
          handleViewChange={handleViewChange}
          dayPropGetter={dayPropGetter}
          setIsLoading={setIsLoading}
        />
      ) : userType === 4 ? (
        <>
          <SpeakerScheduleCalendar
            formats={formats}
            currentView={currentView}
            currentDate={currentDate}
            handleViewChange={handleViewChange}
            dayPropGetter={dayPropGetter}
            setIsLoading={setIsLoading}
          />
        </>
      ) : (
        <StudentScheduleCalendar
          formats={formats}
          currentView={currentView}
          currentDate={currentDate}
          handleViewChange={handleViewChange}
          dayPropGetter={dayPropGetter}
        />
      )}
    </>
  );
};

export default ScheduleCalendar;
