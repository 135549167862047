import React, { useEffect, useState } from "react";
import "./recorded-session.css";
import Loader from "../../components/common/Loader";
import { getSessionsService } from "../../services/sessionsService/sessionsService";
import PreviousSessionCard from "../../components/PreviousSessions/PreviousSessionCard";
import Pagination from '@mui/material/Pagination';

const RecordedSession = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [previousSessions, setPreviousSessions] = useState([]);
    const limit = 5;
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1)

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        getPrevSessions();
    }, []);


    const getPrevSessions = (id = "") => {
        getSessionsService
            .getSessions()
            .then((res) => {
                setIsLoading(false);
                debugger;
                if (res?.data?.data?.completed_key_notes?.length > 0) {
                    setPreviousSessions(res?.data?.data?.completed_key_notes);
                }
            })
            .catch((e) => {
                setIsLoading(false);
            });
    };
    return (
        <>
            <h3 className="d-lg-block d-none">Recorded Session</h3>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {previousSessions.length > 0 ? (
                        <>
                            <div className="card-layout mt-4">
                                <div className="previous-sessions-body">
                                    <div className="row">
                                        {previousSessions.map((data) => {
                                            return <PreviousSessionCard key={data.id} data={data} />;
                                        })}
                                    </div>

                                </div>
                                {total > 0 &&
                                    <div className='pagination-div'>
                                        <Pagination count={total} page={page} onChange={handleChange} />
                                    </div>
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="card-layout-video mt-4">
                                <div className="no-record-msg"> No Sessions Available</div>
                            </div>
                        </>
                    )}


                </>
            )}
        </>
    );
};

export default RecordedSession;
