import apiClient from "../../utils/apiClient";

const CALENDAR_DATA = "/calendar";
const ADD_AVAILABILITY = "/save-availability";
const SPEAKER_AVAILABILITY = "/get-availability";
const SUCCESS_COACH_MEETING_SCHEDULE = "/schedule_meeting";
const SPEAKER_MEETING_SCHEDULE = "/schedule_session";
const CANCEL_SPEAKER_SCHEDULE_MEETING = "/cancel-meeting";
const DELETE_SPEAKER_AVAILABILITY = "/delete-availability";

const getCalendarData = () => {
    const url = `${CALENDAR_DATA}`;
    return apiClient.get(url);
};

const addAvailableSchedule = (data) => {
    const url = `${ADD_AVAILABILITY}`;
    return apiClient.post(url, data);
};

const getSpeakerAvailability = () => {
    const url = `${SPEAKER_AVAILABILITY}`;
    return apiClient.get(url);
};

const scheduleMeeting = (data) => {
    const url = `${SUCCESS_COACH_MEETING_SCHEDULE}`;
    return apiClient.post(url, data);
};

const bookedSpeakerMeetings = (data) => {
    const url = `${SPEAKER_MEETING_SCHEDULE}`;
    return apiClient.post(url, data);
};

const cancelSpeakerScheduleMeeting = (id) => {
    const data = {
        id: id,
    };
    const url = `${CANCEL_SPEAKER_SCHEDULE_MEETING}`;
    return apiClient.post(url, data);
};

const deleteSpeakerAvailability = (id) => {
    const data = {
        id: id,
    };
    const url = `${DELETE_SPEAKER_AVAILABILITY}`;
    return apiClient.post(url, data);
};

export const schedularService = {
    getCalendarData,
    addAvailableSchedule,
    getSpeakerAvailability,
    scheduleMeeting,
    bookedSpeakerMeetings,
    cancelSpeakerScheduleMeeting,
    deleteSpeakerAvailability,
};
