import React, { useState, useEffect } from "react";
import "./UpcomingSessionCountdown.css";
import convertUtcToSelectedTimeZone from "../../utils/common-functions/ConvertUtcToTimezone";

const UpcomingSessionCountdown = ({ video, sessionStartTime, title }) => {
    const [targetTime, setTargetTime] = useState(new Date());
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        setTargetTime(convertUtcToSelectedTimeZone(sessionStartTime));
    }, [sessionStartTime]);

    // Updates the remaining time every second
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const difference = targetTime - now;

            if (difference <= 0) {
                clearInterval(interval);
                setRemainingTime(0);
            } else {
                setRemainingTime(difference);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetTime]);

    // Countdown component
    const Countdown = ({ hours, minutes, remainingSeconds }) => {
        return (
            <div className="d-flex justify-content-center align-items-center gap-3">
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        {hours?.split("").map((digit, index) => {
                            return (
                                <span key={index} className="upcoming-session-digitbox">
                                    {digit}
                                </span>
                            );
                        })}
                        <div className="time-separator">:</div>
                    </div>
                    <div>Hours</div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        {minutes?.split("").map((digit, index) => {
                            return (
                                <span key={index} className="upcoming-session-digitbox">
                                    {digit}
                                </span>
                            );
                        })}
                        <div className="time-separator">:</div>
                    </div>
                    <div>Minutes</div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                    <div className="d-flex gap-2">
                        {remainingSeconds?.split("").map((digit, index) => {
                            return (
                                <span key={index} className="upcoming-session-digitbox">
                                    {digit}
                                </span>
                            );
                        })}
                    </div>
                    <div>Seconds</div>
                </div>
            </div>
        );
    };

    // Takes time in milliseconds and formats it hours, minutes, seconds and renders the Countdown component
    const formatTime = (milliseconds) => {
        const totalSeconds = String(Math.floor(milliseconds / 1000)).padStart(2, "0");
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
        const seconds = String(totalSeconds % 60).padStart(2, "0");

        return <Countdown hours={hours} minutes={minutes} remainingSeconds={seconds} />;
    };

    return (
        <div className="upcoming-session-bg d-flex gap-3 flex-column justify-content-center align-items-center">
            <div className="upcoming-session-title">{title ?? "Next Session will start within"}</div>
            <div>{formatTime(remainingTime)}</div>
        </div>
    );
};

export default UpcomingSessionCountdown;
