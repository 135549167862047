import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Reports.css";
import { reportServices } from "../../services/reportService/ReportServices";
import { toast } from "react-toastify";
import moment from "moment";
const defaultTZ = moment.tz.guess();

const ReportsPage = () => {
    const [selectedReport, setSelectedReport] = useState(null);
    const [eventsAttendee, setEventsAttendee] = useState(null);
    const [attended, setAttended] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isDateVisible, setIsDateVisible] = useState(false);
    const [isKeynoteVisible, setIsKeynoteVisible] = useState(false);
    const [isAttendedVisible, setIsAttendedVisible] = useState(false);
    const [keyNotes, setKeyNotes] = useState([]);
    const [selectedKeyNote, setSelectedKeyNote] = useState(0);

    const reportOptions = [
        { value: "report1", label: "Number of registrants" },
        { value: "report2", label: "Number of Registrants that are engaged ( user login report)" },
        { value: "report3", label: "List of users who attended live events versus users who watched asynchronously" },
        { value: "report4", label: "List of users who have opted out/ unsubscribed" },
    ];

    const eventsAttendees = [
        { value: "1", label: "Live attended" },
        { value: "0", label: "Watched asynchronously" },
    ];
    const attendees = [
        { value: "1", label: "Attended" },
        { value: "0", label: "Not Attended" },
    ];

    const downloadReport = async (res) => {
        try {
            const downloadUrl = res?.data?.data;
            const downloadLink = document.createElement("a");
            downloadLink.href = downloadUrl;
            downloadLink.download = "report.pdf";
            downloadLink.target = "_blank";
            toast.success("Your report has been downloaded successfully");
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading report:", error);
        }
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedReport(selectedOption);
        setIsDateVisible(selectedOption.value === "report2");
        setIsKeynoteVisible(selectedOption.value === "report3");
        setIsAttendedVisible(selectedOption.value === "report4");
    };

    const handleSelectEventAttendee = (selectedOption) => {
        setEventsAttendee(selectedOption);
    };

    const handleSelectAttend = (selectedOption) => {
        setAttended(selectedOption);
    };
    const handleExport = async () => {
        if (selectedReport) {
            const date = moment(selectedDate).format("YYYY-MM-DD");
            try {
                switch (selectedReport.value) {
                    case "report1":
                        const totalRegistrantsResponse = await reportServices.getTotalRegistrants();
                        if (totalRegistrantsResponse?.data?.message) {
                            toast.error(totalRegistrantsResponse?.data?.message);
                        } else {
                            downloadReport(totalRegistrantsResponse);
                        }
                        break;
                    case "report2":
                        const totalRegistrantsEngaged = await reportServices.getTotalRegistrantsEngaged(date);
                        if (totalRegistrantsEngaged?.data?.message) {
                            toast.error(totalRegistrantsEngaged?.data?.message);
                        } else {
                            downloadReport(totalRegistrantsEngaged);
                        }
                        break;
                    case "report3":
                        const totalUserAttendanceEvents = await reportServices.getTotalUserAttendanceEvents(selectedKeyNote, eventsAttendee.value, defaultTZ);
                        if (totalUserAttendanceEvents?.data?.message) {
                            toast.error(totalUserAttendanceEvents?.data?.message);
                        } else {
                            downloadReport(totalUserAttendanceEvents);
                        }
                        break;
                    case "report4":
                        const totalOptedOutStudents = await reportServices.getTotalOptedOutStudents(selectedKeyNote, attended.value);
                        if (totalOptedOutStudents?.data?.message) {
                            toast.error(totalOptedOutStudents?.data?.message);
                        } else {
                            downloadReport(totalOptedOutStudents);
                        }
                        break;
                    default:
                        console.warn("No matching report API found.");
                }
            } catch (error) {}
        }
    };

    const handleKeyNoteChange = (e) => {
        setSelectedKeyNote(e.value);
    };

    const getKeyNotes = () => {
        reportServices.getKeynoteList().then((res) => {
            setKeyNotes(res?.data?.data);
        });
    };

    useEffect(() => {
        getKeyNotes();
    }, []);

    const isExportButtonDisabled = () => {
        switch (selectedReport?.value) {
            case "report1":
                return false;
            case "report2":
                return !selectedDate;
            case "report3":
                return !selectedKeyNote || !eventsAttendee;
            case "report4":
                return !attended || !selectedKeyNote;
            default:
                return true;
        }
    };
    return (
        <>
            <div className="d-flex justify-content-between">
                <h3 className="d-lg-block title d-none">Reports</h3>
            </div>
            <div className="reports-layout mt-2 mt-lg-3 mt-md-3 all-projects-layout">
                <div className="p-2">
                    <h5>Select a Report</h5>
                    <Select options={reportOptions} value={selectedReport} onChange={handleSelectChange} placeholder="Select a report" />
                    {isDateVisible && (
                        <div className="mt-4 d-flex flex-column">
                            <h5>Select Date:</h5>
                            <DatePicker selected={selectedDate} className="form-control mt-2" dateFormat="dd-MM-yyyy" onChange={(date) => setSelectedDate(date)} maxDate={new Date()} />
                        </div>
                    )}
                    {(isKeynoteVisible || isAttendedVisible) && (
                        <div className="mt-4 d-flex flex-column">
                            <h5>Keynotes</h5>
                            <Select
                                onChange={(selectedOption) => handleKeyNoteChange(selectedOption)}
                                options={keyNotes.map((keyNote) => ({
                                    value: keyNote.id,
                                    label: keyNote.name,
                                }))}
                                placeholder="Select keynotes"
                            />
                        </div>
                    )}
                    {isKeynoteVisible && (
                        <div className="mt-4">
                            <h5>Event's Attendees</h5>
                            <Select options={eventsAttendees} value={eventsAttendee} onChange={handleSelectEventAttendee} placeholder="Select a event's attendees" />
                        </div>
                    )}
                    {isAttendedVisible && (
                        <div className="mt-4">
                            <h5>Event's Attended</h5>
                            <Select options={attendees} value={attended} onChange={handleSelectAttend} placeholder="Select a event's attended" />
                        </div>
                    )}
                    <button className="btn btn-primary mt-5" onClick={handleExport} disabled={isExportButtonDisabled()}>
                        Export Report
                    </button>
                </div>
            </div>
        </>
    );
};

export default ReportsPage;
